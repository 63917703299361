import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Divider, Fade } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

export const Introduction: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    // Optional: Hide the notice after a while
    // const timer = setTimeout(() => setVisible(false), 10000);
    // return () => clearTimeout(timer);
  }, []);

  return (
    <Fade in={visible} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          direction: "rtl",
          position: "relative",
          zIndex: 120,
          maxWidth: "800px",
          margin: "20px auto",
          padding: "16px 24px",
          background: "rgba(255, 255, 255, 0.9)",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0 8px 32px rgba(31, 38, 135, 0.2)",
          overflow: "hidden",
          "@keyframes pulse": {
            "0%": { transform: "scale(1)", opacity: 1 },
            "50%": { transform: "scale(1.1)", opacity: 0.8 },
            "100%": { transform: "scale(1)", opacity: 1 }
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, #FF416C 0%, #FF4B2B 100%)",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <MoneyOffIcon color="error" fontSize="large" sx={{ animation: "pulse 2s infinite" }} />

          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                marginBottom: "8px",
                background: "linear-gradient(45deg, #FF416C 0%, #FF4B2B 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              הודעה חשובה
            </Typography>

            <Divider sx={{ margin: "8px 0" }} />

            <Typography variant="h6" sx={{ marginBottom: "10px", color: "#333" }}>
              עקב קשיים תקציביים, נסגרה האפשרות לשאול שאלות באתר כרגע
            </Typography>

            <Typography variant="body2" sx={{ color: "#666", fontSize: "0.9rem" }}>
              אני עובד על פתרון.
            </Typography>
          </Box>

          <InfoIcon color="primary" fontSize="large" sx={{ animation: "pulse 2s infinite" }} />
        </Box>
      </Paper>
    </Fade>
  );
};

export default Introduction;
