import Footer from "./shared/Footer";
import { Box, Button, Container, Typography } from "@mui/material";
import { ChatMain } from "./chat/ChatMain";
import { useEffect, useState } from "react";
import CalendarComponent from "./CalendarComponent";
import ChatMainRag from "./chat/ChatMainRag";
import { useNavigate } from "react-router-dom";

export default function Blog() {
  const [isCalender, setIsCalender] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isCalender = searchParams.get("c");
    console.log(isCalender);

    if (isCalender == "calendar" && !goBack) {
      setIsCalender(true);
    }
  });
  return (
    <>
      <Box>
        <Box>
          <ChatMainRag />
        </Box>

        <Container maxWidth="md" sx={{ textAlign: 'center', my: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
            כלים נוספים
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate('/test-analyzer')}
              sx={{
                minWidth: '200px',
                py: 1.5,
                fontWeight: 'bold',
                fontSize: '1rem'
              }}
            >
              מנתח מבחנים
            </Button>
          </Box>
        </Container>

        <Footer
          title="מוזמנים ליצור קשר ! "
          description="אני זמין במייל yosefbyd@gmail.com"
        />
      </Box>
    </>
  );
}
