import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    IconButton,
    Typography,
    Popper,
    Fade,
    Tooltip,
    CircularProgress,
    useTheme,
    alpha
} from '@mui/material';
import { PopperPlacementType } from '@mui/material/Popper';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { AnalyzerTest } from '../../Constants/interface';

interface PdfPreviewPopperProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    placement: PopperPlacementType;
    pageNumber: number;
    onClose: () => void;
    onFullscreen: () => void;
    handlePdfPreviewLeave: () => void;
    pdfPath: { [key: string]: string };
    selectedTest: AnalyzerTest | null;
}

export const PdfPreviewPopper: React.FC<PdfPreviewPopperProps> = ({
    open,
    anchorEl,
    placement,
    pageNumber,
    onClose,
    onFullscreen,
    handlePdfPreviewLeave,
    pdfPath,
    selectedTest
}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (open) {
            setLoading(true);
            const timer = setTimeout(() => {
                setLoading(false);
            }, 8000);
            return () => clearTimeout(timer);
        }
    }, [open, pageNumber]);

    const subject = selectedTest?.test.subject || 'histology';

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            transition
            style={{ zIndex: 1300 }}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={200}>
                    <Box
                        onMouseEnter={() => { }}
                        onMouseLeave={handlePdfPreviewLeave}
                        sx={{
                            width: 800,
                            height: 1400,
                            position: 'relative',
                            borderRadius: 2,
                            overflow: 'hidden',
                            boxShadow: 6,
                            border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                            backgroundColor: 'background.paper',
                            direction: 'rtl'
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    p: 1,
                                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
                                    backgroundColor: alpha(theme.palette.primary.main, 0.05)
                                }}
                            >
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main', textAlign: 'right' }}>
                                    תצוגה מקדימה - עמוד {pageNumber}
                                </Typography>
                                <Box>
                                    <Tooltip title="הצג במסך מלא" arrow>
                                        <IconButton size="small" onClick={onFullscreen} color="primary">
                                            <FullscreenIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="סגור" arrow>
                                        <IconButton size="small" onClick={onClose} color="error">
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
                                <iframe
                                    src={`${pdfPath[subject]}#page=${pageNumber}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none'
                                    }}
                                    title={`PDF Preview Page ${pageNumber}`}
                                    onLoad={() => setLoading(false)}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: alpha('#fff', 0.7),
                                        zIndex: 1,
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease',
                                        '&.loading': {
                                            opacity: 1
                                        }
                                    }}
                                    className={loading ? 'loading' : ''}
                                >
                                    <CircularProgress size={40} color="primary" />
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Fade>
            )}
        </Popper>
    );
}; 