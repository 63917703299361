import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Slider,
    Checkbox,
    FormControlLabel,
    Button,
    Chip,
    Divider,
    Stack,
    TextField,
    Autocomplete,
    Paper,
    alpha,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Quiz, QuizQuestion, Lecture } from '../Constants/interface';
import { fetchQuiz, fetchTranscription } from '../Transcription/Services/firebaseService';
import { ANALYTICS_CATEGORIES, logCustomEvent } from '../utils/Analytics';
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { groups } from 'd3';
import { QuestionWithLecture, QuizWithLecture } from './QuizMixerPage';

export interface QuizMixerProps {
    lectures: Lecture[];
    onQuizGenerated: (questions: QuestionWithLecture[]) => void;
    quizzes: QuizWithLecture[][];
    setQuizzes: (quizzes: QuizWithLecture[][]) => void;
}

interface SubjectGroup {
    subject: string;
    lectures: Lecture[];
}

export interface DifficultyCount {
    easy: number;
    medium: number;
    hard: number;
}

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    background: theme.palette.background.paper,
    backdropFilter: 'blur(10px)',
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
        transform: 'translateY(-2px)',
    }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.primary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
    '[dir="rtl"] &': {
        '& .MuiSlider-track': {
            right: 0,
            left: 'auto',
        },
        '& .MuiSlider-rail': {
            right: 0,
            left: 'auto',
        },
        '& .MuiSlider-mark': {
            transform: 'translateX(50%)',
        },
        '& .MuiSlider-valueLabel': {
            transformOrigin: 'bottom right',
            transform: 'translate(-50%, -100%) rotate(45deg) scale(0)',
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(-50%, -100%) rotate(45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(-45deg)',
            },
        },
    },
}));

const sortByAlphabet = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

const sortLectures = (a: Lecture, b: Lecture): number => {
    return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
    });
};

const QuizMixer: React.FC<QuizMixerProps> = ({ lectures, onQuizGenerated, quizzes, setQuizzes }) => {
    console.log('QUIZ MIXER LOADED', quizzes);
    const theme = useTheme();
    const [selectedLectures, setSelectedLectures] = useState<Set<string>>(new Set());
    const [selectedTreeItem, setSelectedTreeItem] = useState<string | null>(null);
    const [difficultyCount, setDifficultyCount] = useState<DifficultyCount>({
        easy: 5,
        medium: 5,
        hard: 5,
    });
    const [quizPool, setQuizPool] = useState<QuestionWithLecture[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const groupedLectures = React.useMemo(() => {
        const groups: { [key: string]: { [key: string]: Lecture[] } } = {};
        lectures.forEach(lecture => {
            // Skip lectures that don't have year "2"
            if (lecture.year !== "2") {
                return;
            }
            if (!groups[lecture.subject]) {
                groups[lecture.subject] = {};
            }
            const subFolder = lecture.subFolder || 'כללי';
            if (!groups[lecture.subject][subFolder]) {
                groups[lecture.subject][subFolder] = [];
            }
            groups[lecture.subject][subFolder].push(lecture);
        });

        Object.values(groups).forEach(subfolders => {
            Object.values(subfolders).forEach(lectures => {
                lectures.sort(sortLectures);
            });
        });
        console.log('GROUPED LECTURES ', groups);
        return groups;
    }, [lectures]);

    const maxQuestionsPerLecture = React.useMemo(() => {
        return Math.min(15, Math.max(5, Math.ceil(45 / selectedLectures.size)));
    }, [selectedLectures.size]);
    const [numOfQuestions, setNumOfQuestions] = useState(0);

    // Load quizzes for selected lectures
    useEffect(() => {
        const loadQuizzes = async () => {
            if (selectedLectures.size === 0) {
                setQuizPool([]);
                return;
            }

            setIsLoading(true);
            try {
                const fetchedQuizzes: QuizWithLecture[][] = await Promise.all(
                    Array.from(selectedLectures).map(async (lectureId) => {
                        console.log("lectureId", lectureId)
                        console.log("quizzes", quizzes.flat().map(q => q.lectureId))
                        if (quizzes.flat().map(q => q.lectureId).includes(lectureId)) {
                            return quizzes.find(quiz => quiz.some(q => q.lectureId === lectureId)) || [];
                        }
                        const lecture_quizzes = await fetchQuiz(lectureId);
                        const transcription = (await fetchTranscription(lectureId)).segments;
                        const lecture_quizzes_with_transcription: QuizWithLecture[] = lecture_quizzes.map(quiz => ({ ...quiz, TranscriptionText: transcription }));
                        return lecture_quizzes_with_transcription;
                    })
                );

                const allQuestions: QuestionWithLecture[] = fetchedQuizzes.flatMap(quizArray =>
                    quizArray.flatMap(quiz => quiz.questions.map(q => ({
                        ...q,
                        lectureId: quiz.lectureId,
                        quizId: quiz.id
                    })))
                );

                setQuizPool(allQuestions);
                setQuizzes(fetchedQuizzes);
                const maxPerDifficulty = Math.floor(maxQuestionsPerLecture / 3);

                setDifficultyCount({
                    easy: Math.min(maxPerDifficulty, allQuestions.filter(q => q.difficulty === 'EASY').length),
                    medium: Math.min(maxPerDifficulty, allQuestions.filter(q => q.difficulty === 'MEDIUM').length),
                    hard: Math.min(maxPerDifficulty, allQuestions.filter(q => q.difficulty === 'HARD').length),
                });

                logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'load_quiz_pool', {
                    lecture_count: selectedLectures.size,
                    question_count: allQuestions.length,
                    max_questions_per_lecture: maxQuestionsPerLecture,
                    lectures: JSON.stringify(Array.from(selectedLectures).map(id => lectures.find(l => l.id === id)?.name) || [])
                });
            } catch (error) {
                console.error('Error loading quizzes:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadQuizzes();
    }, [selectedLectures, lectures, maxQuestionsPerLecture]);

    const handleLectureToggle = (lectureId: string) => {
        const newSelected = new Set(selectedLectures);
        if (newSelected.has(lectureId)) {
            newSelected.delete(lectureId);
        } else {
            newSelected.add(lectureId);
        }
        setSelectedLectures(newSelected);

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'toggle_lecture', {
            lecture_id: lectureId,
            is_selected: !selectedLectures.has(lectureId),
            total_selected: newSelected.size,
            lectures: JSON.stringify(lectures.filter(l => newSelected.has(l.id)).map(l => l.name) || [])
        });
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        itemId: string | null
    ) => {
        setSelectedTreeItem(itemId);
        if (itemId) {
            const lecture = lectures.find(l => l.id === itemId);
            if (lecture) {
                handleLectureToggle(itemId);
            }
        }
    };

    const generateMixedQuiz = () => {
        const selectedQuestions: QuestionWithLecture[] = [];
        const difficulties: ('EASY' | 'MEDIUM' | 'HARD')[] = ['EASY', 'MEDIUM', 'HARD'];

        // Group questions by lecture
        const questionsByLecture = quizPool.reduce((acc, question) => {
            if (!acc[question.lectureId]) {
                acc[question.lectureId] = [];
            }
            acc[question.lectureId].push(question);
            return acc;
        }, {} as { [key: string]: QuestionWithLecture[] });

        // For each lecture, select the specified number of questions per difficulty
        Object.entries(questionsByLecture).forEach(([lectureId, lectureQuestions]) => {
            difficulties.forEach(difficulty => {
                const count = difficultyCount[difficulty.toLowerCase() as keyof DifficultyCount];
                const questionsOfDifficulty = lectureQuestions.filter(q => q.difficulty === difficulty);

                // Select random questions for this difficulty from this lecture
                for (let i = 0; i < count && questionsOfDifficulty.length > 0; i++) {
                    const randomIndex = Math.floor(Math.random() * questionsOfDifficulty.length);
                    selectedQuestions.push(questionsOfDifficulty[randomIndex]);
                    questionsOfDifficulty.splice(randomIndex, 1);
                }
            });
        });
        // shuffle selectedQuestions
        selectedQuestions.sort(() => Math.random() - 0.5);
        setNumOfQuestions(selectedQuestions.length);

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'generate_mixed_quiz', {
            question_count: selectedQuestions.length,
            questions_per_lecture: Object.values(difficultyCount).reduce((a, b) => a + b, 0),
            difficulty_distribution: difficultyCount,
            lectures_count: selectedLectures.size
        });

        onQuizGenerated(selectedQuestions);
    };

    useEffect(() => {
        const questionsPerLecture = Object.values(difficultyCount).reduce((a, b) => a + b, 0);
        const totalQuestions = questionsPerLecture * selectedLectures.size;
        setNumOfQuestions(totalQuestions);
    }, [difficultyCount, selectedLectures.size]);

    return (
        <Box sx={{ p: { xs: 0, sm: 2 }, direction: 'rtl', maxWidth: '800px', margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom sx={{
                mb: 3,
                color: theme.palette.text.primary,
                fontWeight: 500,
                textAlign: 'center'
            }}>
                יצירת מבחן מותאם אישית
            </Typography>

            <StyledCard>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 500 }}>
                        בחר הרצאות ({selectedLectures.size} נבחרו)
                    </Typography>
                    <Box sx={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        minHeight: 200,
                        "&::-webkit-scrollbar": {
                            width: "8px",
                            background: "transparent",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "rgba(255, 255, 255, 0.05)",
                            borderRadius: "10px",
                            margin: theme.spacing(1),
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "rgba(255, 255, 255, 0.2)",
                            borderRadius: "10px",
                            border: "2px solid transparent",
                            backgroundClip: "padding-box",
                            transition: "all 0.2s ease",
                            "&:hover": {
                                background: "rgba(255, 255, 255, 0.3)",
                                boxShadow: "0 0 8px rgba(74, 144, 226, 0.5)",
                            },
                        },
                    }}>
                        <SimpleTreeView
                            selectedItems={selectedTreeItem}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            multiSelect={false}
                            slots={{
                                expandIcon: ChevronRightIcon,
                                collapseIcon: ExpandMoreIcon
                            }}
                            sx={{
                                color: theme.palette.text.primary,
                                "& .MuiTreeItem-content": {
                                    padding: "4px 8px",
                                    borderRadius: "8px",
                                    transition: "all 0.2s ease",
                                    "&:hover": {
                                        background: alpha(theme.palette.primary.main, 0.08),
                                    },
                                    "&.Mui-selected": {
                                        background: alpha(theme.palette.primary.main, 0.15),
                                    },
                                },
                                "& .MuiTreeItem-label": {
                                    fontSize: "1.25rem",
                                    fontWeight: 400,
                                    color: theme.palette.text.primary,
                                },
                                "& .MuiTreeItem-group": {
                                    marginLeft: "17px",
                                    paddingLeft: "18px",
                                    borderLeft: `1px dashed ${alpha(theme.palette.divider, 0.4)}`,
                                },
                            }}
                        >
                            {Object.entries(groupedLectures)
                                .sort(([a], [b]) => sortByAlphabet(a, b))
                                .map(([subject, subfolders]) => (
                                    <TreeItem
                                        key={subject}
                                        itemId={subject}
                                        label={
                                            <Typography variant="subtitle2" sx={{
                                                fontWeight: 500,
                                                fontSize: "1rem",
                                                color: theme.palette.text.primary
                                            }}>
                                                {subject}
                                            </Typography>
                                        }
                                    >
                                        {Object.entries(subfolders)
                                            .sort(([a], [b]) => {
                                                // Keep 'כללי' entries at the end
                                                if (a === 'כללי') return 1;
                                                if (b === 'כללי') return -1;
                                                return sortByAlphabet(a, b);
                                            })
                                            .map(([subfolder, lectures]) => (
                                                <TreeItem
                                                    key={`${subject}-${subfolder}`}
                                                    itemId={`${subject}-${subfolder}`}
                                                    sx={{
                                                        fontSize: "1rem",
                                                        marginRight: 2.1
                                                    }}
                                                    label={
                                                        <Typography variant="body2" sx={{
                                                            fontSize: "1rem",
                                                            fontWeight: 500,
                                                            color: theme.palette.text.primary
                                                        }}>
                                                            {subfolder}
                                                        </Typography>
                                                    }
                                                >
                                                    {lectures.map(lecture => (
                                                        <TreeItem
                                                            key={lecture.id}
                                                            itemId={lecture.id}
                                                            label={
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            size='small'
                                                                            checked={selectedLectures.has(lecture.id)}
                                                                            onChange={() => handleLectureToggle(lecture.id)}
                                                                            sx={{
                                                                                color: theme.palette.primary.main,
                                                                                '&.Mui-checked': {
                                                                                    color: theme.palette.primary.main,
                                                                                },
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={lecture.name}
                                                                    sx={{
                                                                        m: 0,
                                                                        '& .MuiFormControlLabel-label': {
                                                                            fontSize: '0.875rem',
                                                                            color: theme.palette.text.primary
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    ))}
                                                </TreeItem>
                                            ))}
                                    </TreeItem>
                                ))}
                        </SimpleTreeView>
                    </Box>
                </CardContent>
            </StyledCard>

            {/* Difficulty Distribution */}
            <StyledCard>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 500 }}>
                        הגדר מספר שאלות לכל הרצאה לפי רמת קושי (עד {maxQuestionsPerLecture} שאלות להרצאה)
                    </Typography>
                    <Box sx={{ px: 3, py: 2 }}>
                        <Typography gutterBottom sx={{ color: theme.palette.success.main, fontWeight: 500 }}>
                            קל ({quizPool.filter(q => q.difficulty === 'EASY').length} שאלות זמינות)
                        </Typography>
                        <StyledSlider
                            value={difficultyCount.easy}
                            onChange={(_, value) => setDifficultyCount(prev => ({ ...prev, easy: value as number }))}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={0}
                            max={Math.min(15, quizPool.filter(q => q.difficulty === 'EASY').length)}
                            sx={{ color: theme.palette.success.main }}
                        />
                        <Typography gutterBottom sx={{ mt: 2, color: theme.palette.warning.main, fontWeight: 500 }}>
                            בינוני ({quizPool.filter(q => q.difficulty === 'MEDIUM').length} שאלות זמינות)
                        </Typography>
                        <StyledSlider
                            value={difficultyCount.medium}
                            onChange={(_, value) => setDifficultyCount(prev => ({ ...prev, medium: value as number }))}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={0}
                            max={Math.min(15, quizPool.filter(q => q.difficulty === 'MEDIUM').length)}
                            sx={{ color: theme.palette.warning.main }}
                        />
                        <Typography gutterBottom sx={{ mt: 2, color: theme.palette.error.main, fontWeight: 500 }}>
                            קשה ({quizPool.filter(q => q.difficulty === 'HARD').length} שאלות זמינות)
                        </Typography>
                        <StyledSlider
                            value={difficultyCount.hard}
                            onChange={(_, value) => setDifficultyCount(prev => ({ ...prev, hard: value as number }))}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={0}
                            max={Math.min(15, quizPool.filter(q => q.difficulty === 'HARD').length)}
                            sx={{ color: theme.palette.error.main }}
                        />
                    </Box>
                </CardContent>
            </StyledCard>

            {/* Generate Button */}
            <Button
                variant="contained"
                fullWidth
                onClick={generateMixedQuiz}
                disabled={selectedLectures.size === 0 || isLoading}
                sx={{
                    mt: 2,
                    py: 1.5,
                    borderRadius: 2,
                    background: theme.palette.primary.main,
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                    transition: 'all 0.3s ease',
                }}
            >
                {isLoading ? 'טוען שאלות...' : 'צור מבחן'} ({numOfQuestions} שאלות)
            </Button>

            {/* Quiz Pool Stats */}
            {quizPool.length > 0 && (
                <Paper
                    sx={{
                        mt: 2,
                        p: 2,
                        borderRadius: 2,
                        bgcolor: alpha(theme.palette.background.paper, 0.8),
                        backdropFilter: 'blur(10px)',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="body2" color="text.secondary">
                            מאגר שאלות זמין: {quizPool.length} שאלות
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            <Chip
                                label={`קל: ${quizPool.filter(q => q.difficulty === 'EASY').length}`}
                                size="small"
                                color="success"
                            />
                            <Chip
                                label={`בינוני: ${quizPool.filter(q => q.difficulty === 'MEDIUM').length}`}
                                size="small"
                                color="warning"
                            />
                            <Chip
                                label={`קשה: ${quizPool.filter(q => q.difficulty === 'HARD').length}`}
                                size="small"
                                color="error"
                            />
                        </Box>
                    </Stack>
                </Paper>
            )}
        </Box>
    );
}

export default QuizMixer; 