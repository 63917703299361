import { AnalyzerTest } from "../Constants/interface";
import { v4 as uuidv4 } from 'uuid';

export function convertToAnalyzerTests(structuredTests: any[]): AnalyzerTest[] {
    const answerMapping = { 'א': '1', 'ב': '2', 'ג': '3', 'ד': '4', 'ה': '5', 'ו': '6', 'ז': '7', 'ח': '8', 'ט': '9', 'י': '10' }
    const getAnswerMapping = (answer: string) => {
        return answerMapping[answer as keyof typeof answerMapping] || null;
    }
    const getAnswerFromTest = (test: any, questionNumber: string) => {
        const answer = test.answers.find((a: any) => a.question_number === questionNumber)?.answer || null;
        return getAnswerMapping(answer) || null;
    }
    return structuredTests.map(test => {
        // Convert backend tesst to frontend AnalyzerTest format
        const analyzerTest: AnalyzerTest = {
            test: {
                id: test.id || '',
                title: test.title || '',
                subject: test?.additional_info?.subject || '',
                date: test?.additional_info?.date || null,
                term: test?.additional_info?.term || null,
                year: test?.additional_info?.year || null,
                organization: test?.additional_info?.organization || null,
                faculty: test?.additional_info?.faculty || null,
                instructors: (test?.additional_info?.lecturers || []).map((name: string) => ({
                    name,
                    topic: '',  // Default empty as backend doesn't provide this
                    department: null
                })),
                metadata: {
                    pdf_path: test.metadata?.pdf_path || '',
                    extraction_model: test.metadata?.extraction_model || '',
                    extraction_date: test.metadata?.extraction_date || '',
                    pages_processed: test.metadata?.pages_processed || 0,
                    total_questions: test.total_questions || 0,
                    has_answers: Array.isArray(test.answers) && test.answers.length > 0,
                    source_file: test.metadata?.source_file || ''
                },
                answerPage: test.answers[0]?.page_number || null
            },
            questions: (test.questions || []).map((q: any) => {
                return {
                    id: q.id || '',
                    test_id: q.test_id || test.id || '',
                    question_number: q.question_number || '',
                    question_text: q.question_text || '',
                    options: (q.options || []).map((opt: any) => ({
                        id: uuidv4(),
                        text: opt || ''
                    })),
                    correct_answer: getAnswerMapping(q.answer) || getAnswerFromTest(test, q.question_number),
                    context: q.context || '',
                    page_number: q.page_number || null,
                    topics: q.topics || [],
                    difficulty: q.difficulty || null,
                    tags: q.tags || [],
                    similar_questions: (q.similar_questions || []).map((sq: any) => ({
                        id: sq.id || '',
                        similarity_score: sq.similarity_score || 0
                    })),
                    image_references: (q.image_references || []).map((img: any) => ({
                        url: img.url || '',
                        caption: img.caption || null,
                        type: img.type || null
                    })),
                    language: q.language || 'he',
                    translation: q.translation || {},
                    metadata: {
                        extraction_confidence: q.metadata?.extraction_confidence || null,
                        last_updated: q.metadata?.last_updated || ''
                    }
                };
            })
        };

        return analyzerTest;
    });
}

// ... existing code ...