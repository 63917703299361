import React, { useMemo } from "react";
import { Typography, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
const frameBaseUrl = "https://transcriptionsdata.blob.core.windows.net/";
interface ParagraphProps {
  content: string;
  styles?: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
}

const Paragraph = React.memo(
  ({ content, styles, showImages, handleImageClick }: ParagraphProps) => {
    const extractImageInfo = (node: any) => {
      const imgElement = node.children?.find(
        (child: any) => child.type === "element" && child.tagName === "img"
      );

      if (!imgElement) return null;

      const { properties } = imgElement;

      return {
        title: properties.title || "",
        src: properties.src.startsWith("frames") ? frameBaseUrl + properties.src : properties.src,
      };
    };

    return (
      <Box sx={{ my: 1, direction: "rtl" }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ children, ...props }) => {
              if (
                props.node?.type === "element" &&
                props.node?.tagName === "p"
              ) {
                const imageInfo = extractImageInfo(props.node);

                if (imageInfo) {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        my: 2,
                      }}
                    >
                      {showImages && (
                        <>
                          <Box
                            component="img"
                            src={imageInfo.src}
                            alt={imageInfo.title}
                            sx={{
                              ...styles?.image,
                              maxWidth: "600px",
                              width: "100%",
                              height: "auto",
                              cursor: "pointer",
                              mb: 1,
                            }}
                            onClick={() => handleImageClick?.(imageInfo.src)}
                          />
                          {imageInfo.title && (
                            <Typography
                              variant="caption"
                              sx={{
                                ...styles?.caption,
                                maxWidth: "600px",
                                textAlign: "center",
                                color: "text.secondary",
                                mt: 1,
                              }}
                            >
                              {imageInfo.title}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  );
                }
              }

              return (
                <Box
                  component="p"
                  sx={{
                    ...styles.markdownTextBody,
                    my: 1,
                    lineHeight: 1.7,
                    textAlign: "right",
                  }}
                >
                  {children}
                </Box>
              );
            },
            strong: ({ children }: any) => (
              <>
                <strong style={{ fontWeight: 600 }}>{children}</strong>
              </>
            ),
            em: ({ children }: any) => <em>{children}</em>,
            ul: ({ children }: any) => (
              <Box
                component="ul"
                sx={{
                  ...styles?.content,
                  pl: 3,
                  my: 1,
                  paddingInlineStart: "40px",
                  paddingInlineEnd: "0",
                }}
              >
                {children}
              </Box>
            ),
            ol: ({ children }) => (
              <Box
                component="ol"
                sx={{
                  ...styles?.content,
                  pl: 3,
                  my: 1,
                  paddingInlineStart: "40px",
                  paddingInlineEnd: "0",
                }}
              >
                {children}
              </Box>
            ),
            li: ({ children }) => (
              <li
                style={{
                  ...styles?.content,
                  marginBottom: "0.5rem",
                  textAlign: "right",
                }}
              >
                {children}
              </li>
            ),
            code: ({ node, inline, children }: any) => {
              const value = String(children).trim();

              // Handle math expressions
              if (value.startsWith('$') && value.endsWith('$')) {
                const mathExpression = value.slice(1, -1);
                return (
                  <span style={{ direction: 'ltr', display: 'inline-block' }}>
                    <InlineMath math={mathExpression} />
                  </span>
                );
              }

              // Return regular code block if not math
              return inline ? (
                <code>{children}</code>
              ) : (
                <pre>
                  <code>{children}</code>
                </pre>
              );
            },
          }}
        >
          {content}
        </ReactMarkdown>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.content === nextProps.content &&
      prevProps.showImages === nextProps.showImages &&
      JSON.stringify(prevProps.styles) === JSON.stringify(nextProps.styles) &&
      prevProps.handleImageClick === nextProps.handleImageClick
    );
  }
);

Paragraph.displayName = "Paragraph";

export default Paragraph;
