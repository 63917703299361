import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Button,
    Stack,
    Divider,
    Card,
    CardContent,
    Chip,
    FormControlLabel,
    Radio,
    RadioGroup,
    Collapse,
    colors,
    IconButton,
    DialogContent,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { QuizQuestion, Lecture, TranscriptionSegment, Quiz } from '../Constants/interface';
import QuizMixer from './QuizMixer';
import { alpha } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { ANALYTICS_CATEGORIES, logCustomEvent } from '../utils/Analytics';
import { fetchLectureMetadata, fetchSummary } from '../Transcription/Services/fetchService';
import FloatingPlayer from '../Transcription/FloatingPlayer';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { createChunkedSegments } from '../Transcription/TranscriptionPlayer';
import { Portal } from '@mui/material';
import { ChunkedSegment } from '../Transcription/TranscriptionPlayer';
import ResponsiveAppBar from '../shared/AppBar';
import { CombinedLectureData, LectureDialogState } from '../chat/LectureReference';
import { createDialogContent, MinimizedContent, DragHandle, FloatingLectureCard } from '../chat/LectureReference';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const PageContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #0A2F5C 0%, #185A9D 50%, #43CEA2 100%)',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(10px)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
}));

const HeaderPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(10px)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

const QuestionCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
    },
}));

const ProgressBar = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '4px',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.spacing(1),
    position: 'relative',
    marginBottom: theme.spacing(3),
}));

const Progress = styled(Box)(({ theme }) => ({
    height: '100%',
    borderRadius: theme.spacing(1),
    transition: 'all 0.3s ease',
    display: 'flex',
}));

const ProgressSegment = styled(Box)(({ theme }) => ({
    height: '100%',
    transition: 'width 0.3s ease',
}));

const StickyProgress = styled(Box)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(8px)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    transition: 'all 0.3s ease',
}));

const getUniqueSubjects = (questions: QuizQuestion[], lectures: Lecture[]): string[] => {
    const subjects = questions.map(q => {
        const lecture = lectures.find(l => l.id === q.source_segment?.[0]?.split('|')[0]);
        return lecture?.subject;
    }).filter((subject): subject is string => subject !== undefined);

    console.log('UNIQUE SUBJECTS ', subjects);

    return Array.from(new Set(subjects));
};
export interface QuizWithLecture extends Quiz {
    TranscriptionText?: TranscriptionSegment[];
}
export interface QuestionWithLecture extends QuizQuestion {
    lectureId: string;
    quizId: string;
}

const QuizMixerPage: React.FC = () => {
    const theme = useTheme();
    const [lectures, setLectures] = useState<Lecture[]>([]);
    const [activeQuestions, setActiveQuestions] = useState<QuestionWithLecture[]>([]);
    const [answeredQuestions, setAnsweredQuestions] = useState<{ [key: number]: number }>({});
    const [showExplanations, setShowExplanations] = useState<{ [key: number]: boolean }>({});
    const [score, setScore] = useState<number | null>(null);
    const [showScore, setShowScore] = useState(false);
    const [quizzes, setQuizzes] = useState<QuizWithLecture[][]>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const audioRef = useRef<HTMLAudioElement>(null);
    const navigate = useNavigate();

    const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
    const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
    const [selectedQuizLecture, setSelectedQuizLecture] = useState<Lecture | null>(null);
    const [showFloatingPlayer, setShowFloatingPlayer] = useState(false);
    const [currentSegmentTime, setCurrentSegmentTime] = useState<string | null>(null);

    const [correctAnswers, setCorrectAnswers] = useState<number>(0);
    const [incorrectAnswers, setIncorrectAnswers] = useState<number>(0);
    const [detachedLectures, setDetachedLectures] = useState<Record<string, LectureDialogState>>({});

    const [lectureColors, setLectureColors] = useState<{ [key: string]: string }>({}); // quizId : color
    const [onClose, setOnClose] = useState<() => void>(() => { });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchLectureMetadata();
                setLectures(data);

                // Modern, vibrant color palette
                const colors = [
                    '#3498db', // Bright Blue
                    '#e74c3c', // Coral Red
                    '#2ecc71', // Emerald Green
                    '#9b59b6', // Amethyst Purple
                    '#f1c40f', // Sunflower Yellow
                    '#1abc9c', // Turquoise
                    '#e67e22', // Carrot Orange
                    '#34495e', // Midnight Blue
                    '#7f8c8d', // Asphalt Gray
                    '#16a085', // Green Sea
                    '#d35400', // Pumpkin Orange
                    '#8e44ad', // Wisteria Purple
                    '#2980b9', // Belize Blue
                    '#c0392b', // Pomegranate Red
                    '#27ae60'  // Nephritis Green
                ];

                const newLectureColors = data.reduce((acc, lecture, index) => {
                    acc[lecture.id] = colors[index % colors.length];
                    return acc;
                }, {} as Record<string, string>);

                setLectureColors(newLectureColors);
            } catch (error) {
                console.warn("Falling back to legacy metadata service:", error);
            }
        };
        fetchData();
    }, []);

    const handleQuizGenerated = (questions: QuestionWithLecture[]) => {
        setActiveQuestions(questions);
        setAnsweredQuestions({});
        setShowExplanations({});
        setScore(null);
        setShowScore(false);

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'generate_mixed_quiz_page', {
            question_count: questions.length,
            subjects: getUniqueSubjects(questions, lectures).join(',')
        });
    };

    const handleAnswerSelect = (questionIndex: number, optionIndex: number) => {
        setAnsweredQuestions(prev => ({ ...prev, [questionIndex]: optionIndex }));
        setShowExplanations(prev => ({ ...prev, [questionIndex]: true }));

        const isCorrect = optionIndex === activeQuestions[questionIndex].correct_answer;
        if (isCorrect) {
            setCorrectAnswers(prev => prev + 1);
        } else {
            setIncorrectAnswers(prev => prev + 1);
        }
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'answer_selected_quiz', {
            question_index: questionIndex,
            option_index: optionIndex,
            is_correct: isCorrect,
            question: activeQuestions[questionIndex].question || '',
            correct_answer: activeQuestions[questionIndex].correct_answer || '',
            option: activeQuestions[questionIndex].options[optionIndex] || ''
        });
        const newAnswers = { ...answeredQuestions, [questionIndex]: optionIndex };
        if (Object.keys(newAnswers).length === activeQuestions.length) {
            const correct = Object.entries(newAnswers).reduce((acc, [idx, answer]) => {
                return acc + (answer === activeQuestions[parseInt(idx)].correct_answer ? 1 : 0);
            }, 0);
            const finalScore = (correct / activeQuestions.length) * 100;
            setScore(finalScore);
            setShowScore(true);

            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, 'quiz_completed', {
                question_count: activeQuestions.length,
                score: finalScore,
                subjects: getUniqueSubjects(activeQuestions, lectures).join(',')
            });
        }
    };

    const handleBack = () => {
        if (activeQuestions.length > 0) {
            // Reset all quiz state
            setActiveQuestions([]);
            setAnsweredQuestions({});
            setShowExplanations({});
            setScore(null);
            setShowScore(false);
            setCorrectAnswers(0);
            setIncorrectAnswers(0);
        } else {
            navigate(-1);
        }
    };

    const timeToSeconds = (time: string): number => {
        console.log("timeToSeconds", time)
        if (!time) return 0;
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const getRelevantTranscription = (
        sourceSegment: string, transcription: TranscriptionSegment[]
    ): ChunkedSegment[] => {
        console.log("getting relevant transcription")

        if (!transcription || !sourceSegment) return [];
        const [start, end] = sourceSegment[0].split(' - ');
        const startTime = timeToSeconds(start);
        const endTime = timeToSeconds(end);

        const relevantSegments = transcription.filter(segment => {
            const segmentStartTime = timeToSeconds(segment.start);
            return segmentStartTime >= startTime && segmentStartTime <= endTime;
        });

        return createChunkedSegments(relevantSegments, 1, false);
    };

    const getDifficultyColor = (difficulty: string) => {
        switch (difficulty) {
            case 'EASY':
                return 'success';
            case 'MEDIUM':
                return 'warning';
            case 'HARD':
                return 'error';
            default:
                return 'default';
        }
    };

    const generateLectureChipColor = useCallback((lectureId: string) => {
        return lectureColors[lectureId] || '#999999'; // fallback color if not found
    }, [lectureColors]);

    const handlePlayLecture = (lectureId: string, sourceSegment: string) => {
        const lecture = lectures.find(l => l.id === lectureId);
        if (!lecture) return;
        console.log("lecture found", lecture)
        const quiz = quizzes.find(q => q[0].lectureId === lectureId);
        if (!quiz || !quiz[0].TranscriptionText) return;
        console.log("quiz found", quiz)
        setSelectedQuizLecture(lecture);
        setShowFloatingPlayer(true);
        setIsPlaying(true);

        const chunks = getRelevantTranscription(sourceSegment, quiz[0].TranscriptionText);
        console.log("chunks", chunks)
        setVisibleChunks(chunks);
        const startTimeSeconds = timeToSeconds(currentSegmentTime || "00:00:00");
        console.log("startTimeSeconds", startTimeSeconds)
        const segmentIndex = chunks.findIndex(chunk => {
            const chunkStartTime = timeToSeconds(chunk.start);
            return chunkStartTime >= startTimeSeconds;
        });
        console.log("segmentIndex", segmentIndex)

        setCurrentSegmentIndex(Math.max(0, segmentIndex));
    };

    const handleHidePlayer = () => {
        setShowFloatingPlayer(false);
        setIsPlaying(false);
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    const handleCloseLecture = (lectureId: string) => {
        setDetachedLectures(prev => {
            const newState = { ...prev };
            delete newState[lectureId];
            return newState;
        });
        if (onClose && detachedLectures[lectureId]?.lecture?.lecture_id === lectureId) {
            onClose();
        }
    };

    const handleMinimizeLecture = (lectureId: string) => {
        setDetachedLectures(prev => {
            const isCurrentlyMinimized = prev[lectureId].isMinimized;

            return {
                ...prev,
                [lectureId]: {
                    ...prev[lectureId],
                    isMinimized: !isCurrentlyMinimized
                }
            };
        });
    };

    const getSummary = async (lectureId: string) => {
        const lectureSummary = await fetchSummary(lectureId);
        return lectureSummary.fullSummary;
    };

    return (
        <>
            <PageContainer>
                <ResponsiveAppBar />
                <PageContainer sx={{ direction: 'rtl' }}>
                    {selectedQuizLecture && showFloatingPlayer && (
                        <Portal>
                            <Box sx={{
                                position: 'fixed',
                                top: { xs: '60%', sm: '70%' },
                                right: { xs: '5%', sm: '30%' },
                                left: { xs: '5%', sm: 'auto' },
                                zIndex: theme.zIndex.modal,
                                width: { xs: '90%', sm: '90%' },
                                maxWidth: { xs: '100%', sm: 800 },
                            }}>
                                <FloatingPlayer
                                    transcription={{
                                        ...selectedQuizLecture,
                                        TranscriptionText: quizzes.find(q => q[0].lectureId === selectedQuizLecture.id)?.[0].TranscriptionText || []
                                    }}
                                    audioRef={audioRef}
                                    totalDuration={selectedQuizLecture.TranscriptionText?.[selectedQuizLecture.TranscriptionText.length - 1]?.end || "00:00:00"}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    playbackRate={playbackRate}
                                    setPlaybackRate={setPlaybackRate}
                                    currentSegmentIndex={currentSegmentIndex}
                                    visibleChunks={visibleChunks}
                                    visible={showFloatingPlayer}
                                    onHide={handleHidePlayer}
                                />
                            </Box>
                        </Portal>
                    )}
                    {Object.values(detachedLectures).map((state, index) => (
                        <Portal key={state.lecture.lecture_id}>
                            <Draggable
                                defaultPosition={state.position}
                                handle=".drag-handle"
                            >
                                <FloatingLectureCard>
                                    <DragHandle className="drag-handle">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <DragIndicatorIcon color="action" />
                                            <Typography variant="subtitle2">
                                                {state.lecture.name}
                                            </Typography>
                                        </Stack>
                                        <Box>
                                            {window.innerWidth > 400 && <IconButton
                                                size="small"
                                                onClick={() => handleMinimizeLecture(state.lecture.lecture_id || '')}
                                            >
                                                {state.isMinimized ? <OpenInFullIcon /> : <MinimizeIcon />}
                                            </IconButton>}
                                            <IconButton
                                                size="small"
                                                onClick={() => handleCloseLecture(state.lecture.lecture_id || '')}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </DragHandle>
                                    <DialogContent>
                                        {state.isMinimized ? (
                                            <MinimizedContent>
                                                <Typography variant="body2" noWrap>
                                                    {state.lecture.name}
                                                </Typography>
                                            </MinimizedContent>
                                        ) : (
                                            createDialogContent(state.lecture, 1, true, true, false, (src: string) => window.open(src, '_blank'))
                                        )}
                                    </DialogContent>
                                </FloatingLectureCard>
                            </Draggable>
                        </Portal>
                    ))}

                    <ContentPaper>
                        {activeQuestions.length === 0 ? (
                            <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
                                <QuizMixer
                                    lectures={lectures}
                                    onQuizGenerated={handleQuizGenerated}
                                    quizzes={quizzes}
                                    setQuizzes={setQuizzes}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <StickyProgress>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        שאלה {Object.keys(answeredQuestions).length + 1} מתוך {activeQuestions.length}
                                    </Typography>
                                    <ProgressBar>
                                        <Progress>
                                            <ProgressSegment sx={{
                                                width: `${(correctAnswers / activeQuestions.length) * 100}%`,
                                                backgroundColor: theme.palette.success.main,
                                            }} />
                                            <ProgressSegment sx={{
                                                width: `${(incorrectAnswers / activeQuestions.length) * 100}%`,
                                                backgroundColor: theme.palette.error.main,
                                            }} />
                                        </Progress>
                                    </ProgressBar>
                                </StickyProgress>
                                {showScore && (
                                    <Paper
                                        sx={{
                                            p: 3,
                                            mb: 3,
                                            borderRadius: 2,
                                            background: alpha(score && score >= 60 ? '#4caf50' : '#f44336', 0.1),
                                            border: `1px solid ${alpha(score && score >= 60 ? '#4caf50' : '#f44336', 0.2)}`
                                        }}
                                    >
                                        <Typography variant="h5" gutterBottom>
                                            ציון סופי: {score?.toFixed(0)}%
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            {score && score >= 60 ? 'כל הכבוד! עברת את המבחן בהצלחה!' : 'המשך להתאמן ונסה שוב.'}
                                        </Typography>
                                    </Paper>
                                )}

                                {activeQuestions.map((question, index) => (
                                    <QuestionCard key={index}>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'right', mb: 2, gap: 1 }}>

                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        שאלה {index + 1} מתוך {activeQuestions.length}
                                                    </Typography>
                                                    <Chip
                                                        label={lectures.find(l => l.id === question.lectureId)?.name}
                                                        color="primary"
                                                        size="small"
                                                        sx={{ borderRadius: 1, p: 1, opacity: 0.8, marginRight: 1, backgroundColor: generateLectureChipColor(question.lectureId) }}
                                                    />

                                                    <Chip
                                                        label={question.source_segment[0].split(' - ')[0]}
                                                        color="primary"
                                                        size="small"
                                                        sx={{ borderRadius: 1, p: 1, opacity: 0.8, marginRight: 1 }}
                                                    />
                                                </Box>
                                                <Chip
                                                    label={question.difficulty}
                                                    color={getDifficultyColor(question.difficulty) as any}
                                                    size="small"
                                                />
                                            </Box>

                                            <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                                                {question.question}
                                            </Typography>

                                            <RadioGroup
                                                value={answeredQuestions[index] ?? -1}
                                                onChange={(e) => handleAnswerSelect(index, parseInt(e.target.value))}
                                            >
                                                {question.options.map((option, optionIndex) => (
                                                    <FormControlLabel
                                                        key={optionIndex}
                                                        value={optionIndex}
                                                        control={<Radio />}
                                                        label={option}
                                                        disabled={showExplanations[index]}
                                                        sx={{
                                                            ...(showExplanations[index] && {
                                                                backgroundColor: optionIndex === question.correct_answer ?
                                                                    alpha(theme.palette.success.main, 0.1) :
                                                                    optionIndex === answeredQuestions[index] ?
                                                                        alpha(theme.palette.error.main, 0.1) :
                                                                        'transparent',
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                margin: '4px 0',
                                                                color: optionIndex === question.correct_answer ?
                                                                    theme.palette.success.main :
                                                                    optionIndex === answeredQuestions[index] ?
                                                                        theme.palette.error.main :
                                                                        theme.palette.text.primary,
                                                                '& .MuiRadio-root': {
                                                                    color: optionIndex === question.correct_answer ?
                                                                        theme.palette.success.main :
                                                                        optionIndex === answeredQuestions[index] ?
                                                                            theme.palette.error.main :
                                                                            theme.palette.primary.main,
                                                                },
                                                                '&:after': optionIndex === question.correct_answer ? {
                                                                    content: '"✓"',
                                                                    marginLeft: 1,
                                                                    color: theme.palette.success.main,
                                                                } : optionIndex === answeredQuestions[index] ? {
                                                                    content: '"✗"',
                                                                    marginLeft: 1,
                                                                    color: theme.palette.error.main,
                                                                } : undefined,
                                                            })
                                                        }}
                                                    />
                                                ))}
                                            </RadioGroup>

                                            <Collapse in={showExplanations[index]}>
                                                <Box sx={{ mt: 2, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                                        {question.explanation}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {question.source_segment.join('; ')}
                                                    </Typography>
                                                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handlePlayLecture(question.lectureId, question.source_segment[0])}
                                                        >
                                                            האזן להקלטה
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={async () => {
                                                                const lecture = lectures.find(l => l.id === question.lectureId);
                                                                if (lecture) {
                                                                    const combinedData: CombinedLectureData = {
                                                                        name: lecture.name,
                                                                        lecture_id: lecture.id,
                                                                        subject: lecture.subject,
                                                                        category: lecture.category,
                                                                        subFolder: lecture.subFolder,
                                                                        location: question.source_segment[0],
                                                                        fullSummary: await getSummary(lecture.id),
                                                                        metadata: {},
                                                                        versionHistory: [],
                                                                        lectureId: lecture.id
                                                                    };
                                                                    setDetachedLectures(prev => ({
                                                                        ...prev,
                                                                        [lecture.id]: {
                                                                            lecture: combinedData,
                                                                            position: {
                                                                                x: Object.keys(prev).length * 30,
                                                                                y: Object.keys(prev).length * 30
                                                                            },
                                                                            isMinimized: false,
                                                                            isDetached: true
                                                                        }
                                                                    }));

                                                                    logCustomEvent(ANALYTICS_CATEGORIES.CONTENT, "open_lecture_summary_quiz", {
                                                                        lecture_id: lecture.id,
                                                                        lecture_name: lecture.name,
                                                                        lecture_subject: lecture.subject,
                                                                        lecture_category: lecture.category,
                                                                        lecture_subFolder: lecture.subFolder
                                                                    });
                                                                }

                                                            }}
                                                            sx={{
                                                                marginRight: 2
                                                            }}
                                                        >
                                                            פתח סיכום בחלונית קטנה
                                                        </Button>
                                                    </Stack>
                                                </Box>
                                            </Collapse>
                                        </CardContent>
                                    </QuestionCard>
                                ))}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleBack}
                                    sx={{ mt: 2 }}
                                >
                                    חזור למערבל
                                </Button>
                            </Box>
                        )}
                    </ContentPaper>
                </PageContainer>
            </PageContainer>
        </>
    );
};

export default QuizMixerPage; 