import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { MarkdownImage } from "./ChatBox"
import ReactMarkdown from "react-markdown"
import { MarkdownTable, MarkdownTableHead, MarkdownTableBody, MarkdownTableRow, MarkdownTableCell } from "./MarkdownTable"

interface ChatMessageComponentProps {
    content: string;
    setEnlargedImage: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ChatMessageComponent: React.FC<ChatMessageComponentProps> = ({ content, setEnlargedImage }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkMath, remarkGfm]}
            rehypePlugins={[rehypeKatex, rehypeRaw]}
            components={{
                img: (props) => <MarkdownImage {...props} setEnlargedImage={setEnlargedImage} />,
                h1: ({ children }) => <h2>{children}</h2>,
                h2: ({ children }) => <h3>{children}</h3>,
                h3: ({ children }) => <h4>{children}</h4>,
                table: MarkdownTable as any,
                thead: MarkdownTableHead as any,
                tbody: MarkdownTableBody as any,
                tr: ({ children, node, ...props }) => (
                    <MarkdownTableRow index={node?.position?.start.line} {...props}>
                        {children}
                    </MarkdownTableRow>
                ),
                th: ({ children }) => <MarkdownTableCell isHeader>{children}</MarkdownTableCell>,
                td: ({ children }) => <MarkdownTableCell>{children}</MarkdownTableCell>,
            }}
        >
            {content}
        </ReactMarkdown>
    )
}
export default ChatMessageComponent;