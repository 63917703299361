import React, { useEffect, useState } from "react";
import {
  CommentsResponse,
  Lecture,
  TranscriptMetadata,
  Term,
} from "../Constants/interface";
import { fetchLectureBasicInfo, fetchLectureMetadata } from "./Services/firebaseService";
import TranscriptionLayout from "./TranscriptionLayout";
import useWindowSize from "./useWindowSize";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent, logPageView } from "../utils/Analytics";
import ResponsiveAppBar from "../shared/AppBar";
import cat1 from "../../assets/cat/1.jpg";
import cat2 from "../../assets/cat/2.jpg";
import cat3 from "../../assets/cat/3.jpg";
import cat4 from "../../assets/cat/4.jpg";
import cat5 from "../../assets/cat/cat5.jpg";
import cat6 from "../../assets/cat/cat6.jpg";
import cat7 from "../../assets/cat/cat7.jpg";
import cat9 from "../../assets/cat/cat9.jpg";
import { fetchCommentsFromDatabase } from "./Services/fetchService";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "./Services/firebaseService";
// Update the gradient function for a stronger, more dynamic look
const getBackgroundGradient = (isArabic: boolean) => {
  if (isArabic) {
    // Arabic section gradient (adjust colors as needed)
    return "linear-gradient(135deg, #2C3E50 0%, #3498DB 50%, #E74C3C 100%)";
  }
  // Default gradient
  return "linear-gradient(135deg, #0A2F5C 0%, #185A9D 50%, #43CEA2 100%)";
};

// Add this new style function
const getMosaicBackground = (selectedCat: string) => {
  const tileSize = "200px";

  return {
    backgroundImage: `
      url("${selectedCat}")
    `,
    backgroundPosition: `
      0 0, ${tileSize} 0,
      0 ${tileSize}, ${tileSize} ${tileSize}
    `,
    backgroundSize: `
      20vw
    `,
    backgroundRepeat: "repeat",
    transition: "background-image 0.5s ease-in-out",
  };
};

export const TranscriptionMain: React.FC = () => {
  const [showCatBackground, setShowCatBackground] = useState<boolean>(true);

  const [selectedCat, setSelectedCat] = useState<string>(cat1);
  const [isTestMode, setIsTestMode] = useState<boolean>(false);
  const [lecture, setLecture] = useState<Lecture | null>(null);
  const [view, setView] = useState<"transcription" | "summary">("summary");
  const [length, setLength] = useState<"short" | "long">("long");
  const [lectureMetadata, setLectureMetadata] = useState<Lecture[]>([]);
  const [isMetadataLoading, setIsMetadataLoading] = useState<boolean>(true);

  const { width } = useWindowSize();
  const isMobile = width ? width <= 600 : false;
  const [playbackRate, setPlaybackRate] = useState(1);
  const [metadata, setMetadata] = useState<Lecture[] | undefined | string>();
  const [isIsolatedLink, setIsIsolatedLink] = useState<boolean>(false);
  const [isArabicSection, setIsArabicSection] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentsResponse[] | undefined>();
  const cats = [cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat9];
  const { lectureId } = useParams<{ lectureId?: string }>();

  const [terms, setTerms] = useState<Term[]>([]);

  useEffect(() => {
    const shuffleCats = () => {
      const shuffled = [...cats].sort(() => Math.random() - 0.5).slice(0, 4);
      setSelectedCat(shuffled[0]);
    };

    // Initial shuffle
    shuffleCats();

    // Shuffle every 5 seconds
    const interval = setInterval(shuffleCats, 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const loadLectureFromId = async () => {
      if (lectureId && lectureMetadata.length > 0) {
        console.log("lectureId is ", lectureId);
        setIsMetadataLoading(true);
        try {
          const foundLecture = lectureMetadata.find(l => l.id === lectureId);
          if (foundLecture) {
            const lecture = await fetchLectureBasicInfo(lectureId);
            setLecture({
              ...lecture,
            });
          }
        } catch (error) {
          console.error("Error loading lecture from ID:", error);
        } finally {
          setIsMetadataLoading(false);
        }
      }
    };

    loadLectureFromId();
  }, [lectureId, lectureMetadata]);

  useEffect(() => {
    logEvent(ANALYTICS_CATEGORIES.CONTENT, "component_view", "TranscriptionMain");
    logPageView();
  }, []);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get("test");
    const sectionParam = urlParams.get("section");
    const isTesting = testParam === "true";
    setIsArabicSection(sectionParam === "gaya_metadata_arabic.json");
    if (sectionParam == "legal_metadata.json") {
      setIsIsolatedLink(true);
    }
    setIsTestMode(isTesting);

    const fetchData = async () => {
      try {
        // First try Firebase service
        const data = await fetchLectureMetadata();
        console.log(data);
        setLectureMetadata(data);
        setIsMetadataLoading(false);
      } catch (error) {
        // Fallback to legacy service
        console.warn("Falling back to legacy metadata service:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const loadLectureFromId = async () => {
      if (lectureId && lectureMetadata.length > 0) {
        console.log("lectureId is ", lectureId);
        setIsMetadataLoading(true);
        try {
          const foundLecture = lectureMetadata.find(l => l.id === lectureId);
          if (foundLecture) {
            const lecture = await fetchLectureBasicInfo(lectureId);
            setLecture({
              ...lecture,
            });
          }
        } catch (error) {
          console.error("Error loading lecture from ID:", error);
        } finally {
          setIsMetadataLoading(false);
        }
      }
    };

    loadLectureFromId();
  }, [lectureId, lectureMetadata]);

  useEffect(() => {
    const fetchComments = async () => {
      // if (lectureMetadata) {
      //   const comments = await fetchCommentsFromDatabase();
      //   setComments(comments);
      // }
    };
    fetchComments();
  }, [lectureMetadata]);

  useEffect(() => {
    try {
      fetch(`${API_BASE_URL}/terms/all`)
        .then((response) => response.json())
        .then((data) => {
          const allTerms = data.terms.map((term: any) => ({
            term: term.term,
            definition: term.explanation,
            subject: term.lecture_subject,
            lectureName: term.lecture_name,
            appearances: term.appearances,
            relatedTerms: term.related_terms,
            lecture_year: term.lecture_year,
            lecture_name: term.lecture_name,
            lecture_id: term.lecture_id,
          }));
          setTerms(allTerms);
        })
        .catch((error) => {
          console.error("Error fetching terms:", error);
        });
    } catch (error) {
      console.error("Error fetching terms:", error);
    }
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        minHeight: "100vh",
        ...(isArabicSection && showCatBackground
          ? getMosaicBackground(selectedCat)
          : { background: getBackgroundGradient(isArabicSection) }),
        position: "relative",
      }}
    >
      {isArabicSection && (
        <Button
          variant="contained"
          sx={{
            position: "fixed",
            top: "12rem",
            left: "2rem",
            zIndex: 1000,
            backgroundColor: showCatBackground ? "error.main" : "success.main",
            "&:hover": {
              backgroundColor: showCatBackground
                ? "error.dark"
                : "success.dark",
            },
            fontFamily: "Arial, sans-serif",
            direction: "rtl",
          }}
          onClick={() => setShowCatBackground((prev) => !prev)}
        >
          {showCatBackground ? "הרוג את החתול" : "החזר את החתול"}
        </Button>
      )}
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          zIndex: 1,
        }}
      >
        {!isIsolatedLink && <ResponsiveAppBar opacity={0.35} />}
        {isMetadataLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                p: 2,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
              }}
            >
              <CircularProgress
                size={60}
                sx={{ color: "rgba(255, 255, 255, 0.2)" }}
              />
              <Typography
                variant="h6"
                sx={{
                  direction: "rtl",
                  marginTop: 2,
                  color: "rgba(255, 255, 255, 0.2)",
                }}
              >
                טוען הרצאות ...
              </Typography>
            </Box>
          </Box>
        ) : (
          <TranscriptionLayout
            isTestMode={isTestMode}
            lecture={lecture}
            setLecture={(newLecture) => {
              setLecture(newLecture);
            }}
            view={view}
            setView={(newView) => {
              logEvent(ANALYTICS_CATEGORIES.CONTENT, "view_change", view);
              setView(newView);
            }}
            length={length}
            setLength={(newLength) => {
              logEvent(ANALYTICS_CATEGORIES.CONTENT, "length_change", length);
              setLength(newLength);
            }}
            lectureMetadata={lectureMetadata}
            isMobile={isMobile}
            comments={comments}
            preSelectedLectureId={lectureId}
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}
            terms={terms}
            sessionId={crypto.randomUUID()}
          />
        )}
      </Box>
    </Box>
  );
};

export default TranscriptionMain;
