import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const MarkdownTable: React.FC<React.PropsWithChildren> = ({ children }) => (
    <TableContainer component={Paper} sx={{ my: 3, boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
        <Table sx={{ minWidth: 300, direction: "rtl" }}>{children}</Table>
    </TableContainer>
);

const MarkdownTableHead: React.FC<React.PropsWithChildren> = ({ children }) => (
    <TableHead sx={{ bgcolor: 'primary.dark', direction: "rtl" }}>{children}</TableHead>
);

const MarkdownTableBody: React.FC<React.PropsWithChildren> = ({ children }) => (
    <TableBody sx={{ direction: "rtl" }}>{children}</TableBody>
);

const MarkdownTableRow: React.FC<React.PropsWithChildren & { index?: number }> = ({ children, index }) => (
    <TableRow sx={{
        direction: "rtl",
        bgcolor: index !== undefined && index % 2 === 0 ? 'action.hover' : 'inherit',
        '&:hover': { bgcolor: 'action.selected' },
    }}>
        {children}
    </TableRow>
);

const MarkdownTableCell: React.FC<React.PropsWithChildren & { isHeader?: boolean }> = ({ children, isHeader }) => (
    <TableCell sx={{
        textAlign: "right",
        direction: "rtl",
        color: isHeader ? 'common.white' : 'text.primary',
        fontWeight: isHeader ? 'bold' : 'normal',
        borderColor: 'divider',
        py: 1.5,
        px: 2,
    }}>
        {children}
    </TableCell>
);

export { MarkdownTable, MarkdownTableHead, MarkdownTableBody, MarkdownTableRow, MarkdownTableCell };