import { styled } from '@mui/material/styles';
import { Box, Paper, Card, alpha } from '@mui/material';

export const PageContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #0A2F5C 0%, #185A9D 50%, #43CEA2 100%)',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

export const ContentPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(10px)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    maxWidth: '1200px',
    margin: '0 auto',
    direction: 'rtl',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}));

export const QuestionCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}));

export const SimilarQuestionsCard = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    background: alpha(theme.palette.background.default, 0.7),
    backdropFilter: 'blur(5px)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
})); 