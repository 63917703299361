import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  keyframes,
  Tooltip,
  Dialog,
  Slide,
  DialogTitle,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ClearIcon from "@mui/icons-material/Clear";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import { componentStyles } from "../styles";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchComponent from "../Transcription/SearchTest";
import SearchIcon from "@mui/icons-material/Search";
import LayersIcon from "@mui/icons-material/Layers";
import { TransitionProps } from "@mui/material/transitions";
import { logEvent, logEventRe } from "../utils/Analytics";
import { ANALYTICS_CATEGORIES } from "../utils/Analytics";
import Fuse from "fuse.js";
import { Term } from "../Constants/interface";
import { API_BASE_URL } from "../Transcription/Services/firebaseService";
import SearchDialog from "../Transcription/SearchDialog";

interface QueryInputProps {
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  query: string;
  handleClickOpen: () => void;
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuerySubmit: () => void;
  loading: boolean;
  handleAligmentChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => void;
  handleShare: () => void;
  alignment: string;
  sessionId: string;
}
const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getButtonColor = (value: string, currentAlignment: string) => {
  const colors = {
    short: { main: "#FF1493", hover: "#FF69B4" },
    medium: { main: "#00CED1", hover: "#20B2AA" },
    long: { main: "rgba(84,58,183,1)", hover: "rgba(72,50,157,1)" },
  };

  return value === currentAlignment
    ? colors[value as keyof typeof colors]
    : undefined;
};

export const QueryInput: React.FC<QueryInputProps> = ({
  alignment,
  handleAligmentChange,
  handleShare,
  setTimer,
  query,
  onQueryChange,
  onQuerySubmit,
  handleClickOpen,
  loading,
  sessionId,
}) => {
  const [animationActive, setAnimationActive] = useState(true);
  const [terms, setTerms] = useState<Term[]>([]);
  const [fuse, setFuse] = useState<Fuse<Term> | null>(null);
  const [isLoadingTerms, setIsLoadingTerms] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const [searchOpen, setSearchOpen] = useState(false);

  const styles = componentStyles;
  const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }

`;
  const pulseAnimationPaper = keyframes`
0% {
  transform: scale(1);
  opacity: 1;
}
50% {
  transform: scale(1.05);
  opacity: 0.7;
}
100% {
  transform: scale(1);
  opacity: 1;
}

`;
  const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
  const borderAnimation = keyframes`
  0% {
    border-image-source: linear-gradient(65deg, #FF1493, #9370DB, #00CED1, #00FA9A);
  }
  50% {
    border-image-source: linear-gradient(65deg, #00FA9A, #FF1493, #9370DB, #00CED1);
  }
  100% {
    border-image-source: linear-gradient(65deg, #FF1493, #9370DB, #00CED1, #00FA9A);
  }
`;
  const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;
  useEffect(() => {
    const tooltipInterval = setInterval(() => {
      setShowTooltip((prev) => !prev);
    }, 10000); // Toggle tooltip every 5 seconds

    const animationTimeout = setTimeout(() => {
      setAnimationActive(false);
    }, 4000); // Stop animations after 5 seconds

    return () => {
      clearInterval(tooltipInterval);
      clearTimeout(animationTimeout);
    };
  }, []);
  const handleClick = () => {
    setTimer(0);
    onQuerySubmit();
  };

  const handleSearch = () => {
    logEventRe(ANALYTICS_CATEGORIES.SEARCH, "search_clicked");
    setSearchOpen(true);
  }

  useEffect(() => {
    try {
      fetch(`${API_BASE_URL}/terms/all`)
        .then((response) => response.json())
        .then((data) => {
          const allTerms = data.terms.map((term: any) => ({
            term: term.term,
            definition: term.explanation,
            subject: term.lecture_subject,
            lectureName: term.lecture_name,
            appearances: term.appearances,
            relatedTerms: term.related_terms,
            lecture_year: term.lecture_year,
            lecture_name: term.lecture_name,
            lecture_id: term.lecture_id,
          }));
          const exludedTerms = ["חן", "רועי", "משפטים", "סטטיסטיקה גאיה", "aa", "unknown", "חן - תרגול"];
          const filteredTerms = allTerms.filter((term: Term) => !exludedTerms.includes(term.lecture_subject ?? "no subject") && term.lecture_year !== "-1");
          setTerms(filteredTerms);

          const fuseInstance: Fuse<Term> = new Fuse(allTerms, {
            keys: [
              { name: "term", weight: 2 },
              { name: "definition", weight: 0.25 },
              { name: "relatedTerms", weight: 0.2 },
            ],
            threshold: 0.25,
            includeScore: true,
          });

          setFuse(fuseInstance);
          setIsLoadingTerms(false);
        })
        .catch((error) => {
          console.error("Error fetching terms:", error);
          setIsLoadingTerms(false);
        });
    } catch (error) {
      console.error("Error fetching terms:", error);
      setIsLoadingTerms(false);
    }
  }, []);


  return (
    <Box
      sx={{
        direction: "rtl",
        maxWidth: "800px",
        width: { xs: "80vw", sm: "70vw" },
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Answer type selection - Moved to top right */}
      {!loading && (
        <Box
          sx={{
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {/* Search button - Positioned separately */}

          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleAligmentChange}
            aria-label="Platform"
            size="small"
            sx={{
              "& .MuiToggleButton-root": {
                color: "rgba(255, 255, 255, 0.7)",
                borderColor: "rgba(255, 255, 255, 0.2)",
                padding: "4px 8px",
                "&.Mui-selected": {
                  backgroundColor: (theme) =>
                    getButtonColor(theme.palette.mode, alignment)?.main ||
                    "rgba(255, 255, 255, 0.15)",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor: (theme) =>
                    getButtonColor(theme.palette.mode, alignment)?.hover ||
                    "rgba(255, 255, 255, 0.1)",
                },
              },
            }}
          >
            <ToggleButton
              value="short"
              sx={{
                transition: "background-color 0.3s ease",
              }}
            >
              <Tooltip title="תשובה קצרה וממוקדת">
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  קצר <ShortTextIcon sx={{ fontSize: "1rem" }} />
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="medium">
              <Tooltip title="תשובה מפורטת עם הסברים">
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  מפורט <SubjectIcon sx={{ fontSize: "1rem" }} />
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="long">
              <Tooltip title="תשובה מעמיקה עם דוגמאות">
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  מעמיק <ListAltIcon sx={{ fontSize: "1rem" }} />
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="documents">
              <Tooltip title="תשובה מבוססת על מאגר ההרצאות">
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  הרצאות <LayersIcon sx={{ fontSize: "1rem" }} />
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            sx={{
              zIndex: 2,
            }}
          >
            <Tooltip title="חיפוש במאגר המונחים" placement="top">
              <Button
                variant="outlined"
                size="small"
                onClick={handleSearch}
                disabled={isLoadingTerms}
                endIcon={<SearchIcon sx={{ fontSize: "18px", mr: 1 }} />}
                sx={{
                  mb: "15px",
                  color: "white",
                  fontSize: "0.85rem",
                  padding: "4px 8px",
                  minHeight: "32px",
                  textTransform: "none",
                  fontWeight: "normal",
                  position: "relative",
                  border: "2px solid transparent",
                  borderRadius: "4px",
                  backgroundImage: "none",
                  backgroundColor: "transparent",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -2,
                    left: -2,
                    right: -2,
                    bottom: -2,
                    borderRadius: "6px",
                    padding: "2px",
                    background: isLoadingTerms
                      ? "linear-gradient(90deg, #FF149333, #9370DB33, #00CED133, #00FA9A33, #FF149333)"
                      : "linear-gradient(65deg, #FF1493, #9370DB, #00CED1, #00FA9A)",
                    backgroundSize: isLoadingTerms ? "200% 100%" : "300% 300%",
                    animation: isLoadingTerms
                      ? `${shimmerAnimation} 1.5s infinite linear`
                      : `${gradientAnimation} 5s ease infinite`,
                    WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    border: "2px solid transparent",
                  },
                  "&:disabled": {
                    opacity: isLoadingTerms ? 1 : 0.5,
                    color: "white",
                  }
                }}
              >
                {isLoadingTerms
                  ? "טוען מונחים..."
                  : (window.innerWidth > 600 ? "חפש מונח ספיציפי" : "מונחים")
                }
              </Button>
            </Tooltip>
          </Box>
        </Box>
      )}

      <Box sx={{ position: "relative" }}>
        <TextField
          maxRows={14}
          multiline
          minRows={3}
          label="שאלו שאלה בשפת הקודש"
          variant="filled"
          fullWidth
          value={query}
          onChange={onQueryChange}
          sx={{
            width: "100%",
            "& label": {
              right: "20px",
              left: "auto",
              direction: "rtl",
              color: "white",
            },
            "& label.Mui-focused": {
              right: "20px",
              left: "auto",
              direction: "rtl",
              color: "white",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "white",
            },
            "& .MuiFilledInput-input": {
              direction: "rtl",
              color: "white",
              paddingBottom: "48px", // Make room for the buttons
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "rgba(255, 255, 255, 0.15)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.25)",
              },
            },
          }}
        />

        {/* Action buttons container - Split into two parts */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "15px",
          }}
        >
          {/* Send button on the right */}
          <Box>
            <span>
              <Button
                size="small"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  minWidth: "70px", // Wider rectangle shape
                  height: "36px",
                  borderRadius: "4px", // Rectangle corners
                  padding: "0 16px",
                  backgroundColor: "rgba(247, 128, 52, 0.8)",
                  "&:hover": {
                    backgroundColor: "rgb(202, 81, 6)",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "rgba(247, 128, 52, 0.4)",
                  },
                }}
                onClick={handleClick}
                disabled={loading}
              >
                שלח שאלה
                <SendSharpIcon
                  sx={{ mr: 1, fontSize: "15px", color: "white" }}
                />
              </Button>
            </span>
          </Box>

          {/* Other buttons on the left */}
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Tooltip title="שתף שיחה" placement="top" sx={{ direction: "ltr" }}>
              <Button
                size="small"
                sx={{
                  minWidth: "36px",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  padding: 0,
                  backgroundColor: "rgba(188, 28, 241, 0.8)",
                  "&:hover": {
                    backgroundColor: "#d61dff",
                  },
                }}
                onClick={handleShare}
              >
                <WhatsAppIcon sx={{ fontSize: "20px", color: "white" }} />
              </Button>
            </Tooltip>

            <Tooltip title="נקה שיחה" placement="top" sx={{ direction: "ltr" }}>
              <Button
                size="small"
                sx={{
                  minWidth: "36px",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  padding: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                  },
                }}
                onClick={handleClickOpen}
              >
                <ClearIcon sx={{ fontSize: "20px", color: "white" }} />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* Search Dialog */}
      {fuse && (
        <SearchDialog
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          terms={terms}
          fuse={fuse}
          sessionId={sessionId}
        />
      )}
    </Box>
  );
};

export default QueryInput;
