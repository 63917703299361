import React, { useState, useEffect } from 'react';

interface PdfViewerProps {
    file?: string;
    pageNumber?: number;
    subject?: string;
    pdfPath: { [key: string]: string };
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ file, pageNumber, subject, pdfPath }) => {
    const [key, setKey] = useState(0);
    const pdfFile = subject ?
        (subject.includes('/') ? subject : pdfPath[subject]) :
        pdfPath['immunology'];
    const pageParam = pageNumber ? `#page=${pageNumber}` : '';

    useEffect(() => {
        setKey(prev => prev + 1);
    }, [pageNumber]);

    return (
        <iframe
            key={key}
            src={`${pdfFile}${pageParam}`}
            style={{
                width: '100%',
                height: '100%',
                border: 'none'
            }}
            title={`PDF Viewer ${pageNumber ? `- Page ${pageNumber}` : ''}`}
        />
    );
}; 