import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Button,
    Stack,
    Divider,
    Card,
    CardContent,
    Chip,
    IconButton,
    Collapse,
    alpha,
    useTheme,
    Tooltip,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Popper,
    Fade,
    ClickAwayListener,
    Zoom,
    Badge,
    CircularProgress,
    Slider,
    useMediaQuery,
    Portal
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalyzerTest, AnalyzerQuestion } from '../Constants/interface';
import TestAnalyzer from './TestAnalyzer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ResponsiveAppBar from '../shared/AppBar';
import { PopperPlacementType } from '@mui/material/Popper';
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent, logPageView } from '../utils/Analytics';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { FloatingLectureCard, DragHandle, MinimizedContent } from '../chat/LectureReference';
import { convertToAnalyzerTests } from './QuizUtils';

import {
    PageContainer,
    ContentPaper,
    QuestionCard,
    SimilarQuestionsCard,
    PdfPreviewPopper,
    PdfViewer,
    AnswerHoverCard,
    DraggablePdfViewer,
    getOptionBackgroundColor,
    getOptionBorderColor,
    calculateScore
} from './components';

import type { DraggablePdfState } from './components/types';

const TestAnalyzerPage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // Update to support multiple test sets
    const [testSets, setTestSets] = useState<{ [key: string]: AnalyzerTest[] }>({});
    const [activeTestSet, setActiveTestSet] = useState<string>('histology');
    const [selectedQuestions, setSelectedQuestions] = useState<AnalyzerQuestion[]>([]);
    const [expandedQuestions, setExpandedQuestions] = useState<{ [key: string]: boolean }>({});
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState<number | null>(null);
    const [pdfPreviewAnchorEl, setPdfPreviewAnchorEl] = useState<HTMLElement | null>(null);
    const [previewPageNumber, setPreviewPageNumber] = useState<number | null>(null);
    const [similarityThreshold, setSimilarityThreshold] = useState<number>(0.1);

    // Update pdfPath to be more dynamic
    const pdfPath: { [key: string]: string } = {
        'histology': 'tests/histology/histology.pdf',
        'immunology': 'tests/immunology/immunology.pdf',
        'anatomy': 'tests/anatomy/anatomy.pdf',
        'physiology': 'tests/physiology/physiology.pdf'
    };

    const availableTestSets = [
        { id: 'histology', name: 'היסטולוגיה' },
        { id: 'immunology', name: 'אימונולוגיה' },
        { id: 'anatomy', name: 'אנטומיה' },
        { id: 'physiology', name: 'פיזיולוגיה' }
    ];

    const navigate = useNavigate();
    const previewTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
    const [userAnswers, setUserAnswers] = useState<{ [questionId: string]: string }>({});
    const [quizMode, setQuizMode] = useState(true);
    const [showResults, setShowResults] = useState(false);
    const [score, setScore] = useState({ correct: 0, total: 0 });
    const [immediateAnswerFeedback, setImmediateAnswerFeedback] = useState(true);
    const [draggablePdf, setDraggablePdf] = useState<DraggablePdfState | null>(null);

    useEffect(() => {
        // Track page view when component mounts
        logPageView();
        logEvent(ANALYTICS_CATEGORIES.QUIZ, "test_analyzer_page_loaded");
    }, []);

    useEffect(() => {
        const loadTests = async () => {
            try {
                // Load the active test set if not already loaded
                if (!testSets[activeTestSet]) {
                    try {
                        const response = await fetch(`tests/${activeTestSet}/${activeTestSet}.json`);
                        const responseData = await response.json();
                        const testsData = ['immunology', 'anatomy'].includes(activeTestSet) ? convertToAnalyzerTests(responseData) : responseData;

                        console.log(`${activeTestSet} tests loaded:`, testsData);

                        // Update the testSets state with the new data
                        setTestSets(prev => ({
                            ...prev,
                            [activeTestSet]: testsData
                        }));

                        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "tests_loaded", {
                            test_count: testsData.length,
                            subject: activeTestSet
                        });
                    } catch (error) {
                        console.error(`Error loading ${activeTestSet} tests:`, error);
                        logEvent(ANALYTICS_CATEGORIES.ERROR, "tests_load_error", String(error));
                    }
                }
            } catch (error) {
                console.error(`Error loading ${activeTestSet} tests:`, error);
                logEvent(ANALYTICS_CATEGORIES.ERROR, "tests_load_error", String(error));
            }
        };

        loadTests();
    }, [activeTestSet, testSets]);

    // Add function to change active test set
    const handleChangeTestSet = (testSetId: string) => {
        setActiveTestSet(testSetId);
        // Reset selected questions when changing test set
        setSelectedQuestions([]);
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "test_set_changed", {
            test_set: testSetId
        });
    };

    const handleQuestionsSelected = (questions: AnalyzerQuestion[]) => {
        setSelectedQuestions(questions);

        // Initialize expanded state for all questions (collapsed by default)
        const initialExpandedState: { [key: string]: boolean } = {};
        questions.forEach(q => {
            initialExpandedState[q.id] = false;
        });
        setExpandedQuestions(initialExpandedState);

        // Log analytics for questions selected
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "questions_selected", {
            question_count: questions.length,
            topics: Array.from(new Set(questions.flatMap(q =>
                Array.isArray(q.topics) ? q.topics :
                    (typeof q.topics === 'string' ? [q.topics] : [])
            ))).join(','),
            test_set: activeTestSet
        });
    };

    const handleBack = () => {
        if (selectedQuestions.length > 0) {
            // Reset to test selection view
            setSelectedQuestions([]);
            logEvent(ANALYTICS_CATEGORIES.QUIZ, "back_to_test_selection");
        } else {
            navigate(-1);
            logEvent(ANALYTICS_CATEGORIES.NAVIGATION, "exit_test_analyzer");
        }
    };

    const toggleQuestionExpanded = (questionId: string) => {
        setExpandedQuestions(prev => ({
            ...prev,
            [questionId]: !prev[questionId]
        }));

        // Log when a question is expanded or collapsed
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "toggle_question_expanded", {
            question_id: questionId,
            expanded: !expandedQuestions[questionId]
        });
    };

    // Find a question by ID across all tests
    const findQuestionById = (questionId: string): AnalyzerQuestion | undefined => {
        for (const testSetKey in testSets) {
            for (const test of testSets[testSetKey]) {
                const question = test.questions.find(q => q.id === questionId);
                if (question) return question;
            }
        }
        return undefined;
    };

    // Find the test that contains a specific question
    const findTestByQuestionId = (questionId: string): AnalyzerTest | undefined => {
        for (const testSetKey in testSets) {
            const test = testSets[testSetKey].find(test =>
                test.questions.some(q => q.id === questionId)
            );
            if (test) return test;
        }
        return undefined;
    };

    // Group questions by test
    const groupQuestionsByTest = (questions: AnalyzerQuestion[]): { [key: string]: AnalyzerQuestion[] } => {
        const grouped: { [key: string]: AnalyzerQuestion[] } = {};

        questions.forEach(question => {
            if (!grouped[question.test_id]) {
                grouped[question.test_id] = [];
            }
            grouped[question.test_id].push(question);
        });

        return grouped;
    };
    const getSubjectForQuestion = (questionId: string): string => {
        for (const testSetKey in testSets) {
            const test = testSets[testSetKey].find(test =>
                test.questions.some(q => q.id === questionId)
            );
            if (test) return test.test.subject;
        }
        return activeTestSet; // Default to active test set if not found
    };

    // Update openPdfViewer to use the correct PDF path based on the question's subject
    const openPdfViewer = (pageNumber: number, questionId: string) => {
        console.log("openPdfViewer", pageNumber, questionId);
        // Get the subject directly from the active test set
        const subject = activeTestSet;

        if (draggablePdf) {
            setCurrentPageNumber(pageNumber);
            setDraggablePdf({
                ...draggablePdf,
                pageNumber: pageNumber,
                subject: subject
            });
            return;
        }

        // Calculate center position based on window dimensions and viewer size
        const viewerWidth = 800; // Default width of the viewer
        const viewerHeight = 1000; // Default height of the viewer

        // Center horizontally and vertically
        const centerX = Math.max(0, (window.innerWidth - viewerWidth) / 2);
        const centerY = Math.max(0, (window.innerHeight - viewerHeight) / 2);

        setDraggablePdf({
            position: { x: 0, y: 0 },
            isMinimized: false,
            pageNumber: pageNumber,
            isFullSize: false,
            subject: subject
        });

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "pdf_viewer_opened", {
            page_number: pageNumber,
            view_type: "draggable",
            subject: subject
        });
    };

    const handlePdfPreviewEnter = (event: React.MouseEvent<HTMLElement>, pageNumber: number | null, questionId?: string) => {
        if (!pageNumber) return;
        setPdfPreviewAnchorEl(event.currentTarget);
        setPreviewPageNumber(pageNumber);

        // Use activeTestSet directly as the subject
        const subject = activeTestSet;

        // Clear any existing timeout
        if (previewTimeoutRef.current) {
            clearTimeout(previewTimeoutRef.current);
            previewTimeoutRef.current = null;
        }

        // Set a short delay before showing the preview
        previewTimeoutRef.current = setTimeout(() => {
            setPdfPreviewOpen(true);
            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "pdf_preview_opened", {
                page_number: pageNumber,
                subject: subject
            });
        }, 500);
    };

    const handlePdfPreviewLeave = () => {
        // Set a short delay before hiding the preview to prevent flickering
        if (previewTimeoutRef.current) {
            clearTimeout(previewTimeoutRef.current);
        }

        previewTimeoutRef.current = setTimeout(() => {
            setPdfPreviewOpen(false);
        }, 10);

        logEvent(ANALYTICS_CATEGORIES.QUIZ, "pdf_preview_closed");
    };

    const closePdfPreview = () => {
        setPdfPreviewOpen(false);
        setPreviewPageNumber(null);
        setPdfPreviewAnchorEl(null);

        if (previewTimeoutRef.current) {
            clearTimeout(previewTimeoutRef.current);
            previewTimeoutRef.current = null;
        }
    };

    const handleOptionSelect = (questionId: string, optionIndex: number) => {
        if (!quizMode || showResults) return;

        // Fix: Convert optionIndex (0-based) to 1-based for comparison with correct_answer
        const isCorrect = (optionIndex + 1).toString() === selectedQuestions.find(q => q.id === questionId)?.correct_answer;

        setUserAnswers(prev => ({
            ...prev,
            [questionId]: (optionIndex + 1).toString()
        }));

        // Update score immediately if immediate feedback is enabled
        if (immediateAnswerFeedback) {
            setScore(prev => {
                // If this question was already answered, adjust the score
                const wasCorrectBefore = prev.correct > 0 &&
                    userAnswers[questionId] === selectedQuestions.find(q => q.id === questionId)?.correct_answer;

                let newCorrect = prev.correct;

                // If it was correct before but now wrong, decrement
                if (wasCorrectBefore && !isCorrect) {
                    newCorrect--;
                }
                // If it was wrong before but now correct, increment
                else if (!wasCorrectBefore && isCorrect) {
                    newCorrect++;
                }
                // If this is a new answer and it's correct, increment
                else if (!userAnswers[questionId] && isCorrect) {
                    newCorrect++;
                }

                return {
                    correct: newCorrect,
                    total: prev.total
                };
            });
        }

        // Log when user selects an answer option
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "answer_selected", {
            question_id: questionId,
            option_index: optionIndex,
            is_correct: isCorrect
        });
    };

    const calculateScore = () => {
        if (!immediateAnswerFeedback) {
            // Only recalculate if not using immediate feedback
            let correct = 0;
            let total = selectedQuestions.length;

            selectedQuestions.forEach(question => {
                if (userAnswers[question.id] && userAnswers[question.id] === question.correct_answer) {
                    correct++;
                }
            });

            setScore({ correct, total });
        } else {
            // Just update the total if using immediate feedback
            setScore(prev => ({
                ...prev,
                total: selectedQuestions.length
            }));
        }

        setShowResults(true);

        // Log quiz completion with score
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_completed", {
            correct_answers: score.correct,
            total_questions: selectedQuestions.length,
            score_percentage: Math.round((score.correct / selectedQuestions.length) * 100),
            question_count: selectedQuestions.length,
            immediate_feedback: immediateAnswerFeedback
        });
    };

    // Add function to toggle immediate feedback
    const toggleImmediateFeedback = () => {
        setImmediateAnswerFeedback(prev => !prev);

        // Reset score when toggling feedback mode
        if (Object.keys(userAnswers).length > 0) {
            resetQuiz();
        }

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "immediate_feedback_toggled", {
            enabled: !immediateAnswerFeedback
        });
    };

    // Add function to reset quiz
    const resetQuiz = () => {
        setUserAnswers({});
        setShowResults(false);
        setScore({ correct: 0, total: 0 });
        logEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_reset");
    };

    // Add function to toggle quiz mode
    const toggleQuizMode = () => {
        if (quizMode && !showResults) {
            // If we're in quiz mode but haven't shown results, ask for confirmation
            if (window.confirm('האם אתה בטוח שברצונך לצאת ממצב מבחן? התשובות שלך יאבדו.')) {

                setQuizMode(false);
                resetQuiz();
                logEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_mode_exited_with_confirmation");
            }
        } else {
            setQuizMode(!quizMode);
            resetQuiz();
            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_mode_toggled", {
                mode: !quizMode ? "quiz" : "study",
                question_count: selectedQuestions.length
            });
        }
    };

    // Add helper functions for option styling
    const getOptionBackgroundColor = (
        question: AnalyzerQuestion,
        optionIndex: number,
        userAnswers: { [questionId: string]: string },
        showResults: boolean,
        quizMode: boolean
    ) => {
        const isAnswered = userAnswers[question.id] !== undefined;
        // Fix: Convert optionIndex (0-based) to 1-based for comparison with correct_answer
        const isCorrectAnswer = (optionIndex + 1).toString() === question.correct_answer;
        const isUserAnswer = (optionIndex + 1).toString() === userAnswers[question.id];

        if (!quizMode && isCorrectAnswer) {
            // In normal mode, highlight correct answer
            return alpha(theme.palette.success.main, 0.1);
        } else if (quizMode && (showResults || (immediateAnswerFeedback && isAnswered))) {
            // In quiz mode with results shown OR immediate feedback enabled and question answered
            if (isCorrectAnswer) {
                // Correct answer
                return alpha(theme.palette.success.main, 0.15);
            } else if (isUserAnswer && !isCorrectAnswer) {
                // User's incorrect answer
                return alpha(theme.palette.error.main, 0.15);
            }
        } else if (quizMode && isUserAnswer) {
            // User's selected answer in quiz mode (when immediate feedback is disabled)
            return alpha(theme.palette.primary.main, 0.15);
        }

        return alpha(theme.palette.background.default, 0.5);
    };

    const getOptionBorderColor = (
        question: AnalyzerQuestion,
        optionIndex: number,
        userAnswers: { [questionId: string]: string },
        showResults: boolean,
        quizMode: boolean
    ) => {
        const isAnswered = userAnswers[question.id] !== undefined;
        // Fix: Convert optionIndex (0-based) to 1-based for comparison with correct_answer
        const isCorrectAnswer = (optionIndex + 1).toString() === question.correct_answer;
        const isUserAnswer = (optionIndex + 1).toString() === userAnswers[question.id];

        if (!quizMode && isCorrectAnswer) {
            // In normal mode, highlight correct answer
            return `1px solid ${alpha(theme.palette.success.main, 0.5)}`;
        } else if (quizMode && (showResults || (immediateAnswerFeedback && isAnswered))) {
            // In quiz mode with results shown OR immediate feedback enabled and question answered
            if (isCorrectAnswer) {
                // Correct answer
                return `2px solid ${alpha(theme.palette.success.main, 0.7)}`;
            } else if (isUserAnswer && !isCorrectAnswer) {
                // User's incorrect answer
                return `2px solid ${alpha(theme.palette.error.main, 0.7)}`;
            }
        } else if (quizMode && isUserAnswer) {
            // User's selected answer in quiz mode (when immediate feedback is disabled)
            return `2px solid ${alpha(theme.palette.primary.main, 0.7)}`;
        }

        return `1px solid ${alpha(theme.palette.divider, 0.1)}`;
    };

    // Add handlers for the draggable PDF viewer
    const handleCloseDraggablePdf = () => {
        setDraggablePdf(null);
        logEvent(ANALYTICS_CATEGORIES.QUIZ, "draggable_pdf_closed");
    };

    const handleMinimizeDraggablePdf = () => {
        if (draggablePdf) {
            setDraggablePdf({
                ...draggablePdf,
                isMinimized: !draggablePdf.isMinimized
            });
            logEvent(ANALYTICS_CATEGORIES.QUIZ, draggablePdf.isMinimized ? "pdf_maximized" : "pdf_minimized");
        }
    };

    const handleTogglePdfSize = () => {
        if (draggablePdf) {
            setDraggablePdf({
                ...draggablePdf,
                isFullSize: !draggablePdf.isFullSize
            });
            logEvent(ANALYTICS_CATEGORIES.QUIZ, draggablePdf.isFullSize ? "pdf_size_reduced" : "pdf_size_increased");
        }
    };

    const handleChangePdfPage = (increment: number) => {
        if (draggablePdf) {
            const newPage = Math.max(1, Math.min(100, draggablePdf.pageNumber + increment));
            setDraggablePdf({
                ...draggablePdf,
                pageNumber: newPage
            });
            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "pdf_page_changed", {
                page_number: newPage
            });
        }
    };

    return (
        <>
            <PageContainer>
                <ResponsiveAppBar />
                <PageContainer sx={{ direction: 'rtl' }}>
                    <ContentPaper>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: { xs: 2, sm: 3 },
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 1, sm: 0 }
                        }}>
                            <IconButton
                                onClick={handleBack}
                                sx={{
                                    mr: { xs: 0, sm: 1 },
                                    order: { xs: 2, sm: 1 }
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                    order: { xs: 1, sm: 2 }
                                }}
                            >
                                {selectedQuestions.length > 0 ? 'ניתוח שאלות' : 'מנתח מבחנים'}
                            </Typography>
                        </Box>

                        {selectedQuestions.length === 0 ? (
                            <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="h6" gutterBottom sx={{
                                        textAlign: 'right',
                                        fontSize: { xs: '1rem', sm: '1.25rem' }
                                    }}>
                                        בחר נושא מבחן:
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {availableTestSets.map((testSet) => (
                                            <Grid item xs={6} sm={3} key={testSet.id}>
                                                <Button
                                                    variant={activeTestSet === testSet.id ? "contained" : "outlined"}
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => handleChangeTestSet(testSet.id)}
                                                    sx={{
                                                        p: { xs: 1, sm: 2 },
                                                        borderRadius: 2,
                                                        fontWeight: 'bold',
                                                        fontSize: { xs: '0.875rem', sm: '1rem' },
                                                        transition: 'all 0.2s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 2
                                                        }
                                                    }}
                                                >
                                                    {testSet.name}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                {/* Show loading indicator if tests are not loaded yet */}
                                {!testSets[activeTestSet] ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <TestAnalyzer
                                        tests={testSets[activeTestSet]}
                                        onQuestionsSelected={handleQuestionsSelected}
                                        similarityThreshold={similarityThreshold}
                                        onSimilarityThresholdChange={setSimilarityThreshold}
                                        activeTestSet={activeTestSet}
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: { xs: 2, sm: 3 },
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 1, sm: 0 }
                                }}>
                                    <Typography variant="h6" gutterBottom sx={{
                                        textAlign: 'right',
                                        mb: { xs: 0, sm: 0 },
                                        fontSize: { xs: '1rem', sm: '1.25rem' }
                                    }}>
                                        {selectedQuestions.length} שאלות נמצאו
                                    </Typography>

                                    <Box sx={{
                                        display: 'flex',
                                        gap: { xs: 1, sm: 2 },
                                        width: { xs: '100%', sm: 'auto' },
                                        flexDirection: { xs: 'column', sm: 'row' }
                                    }}>
                                        {quizMode && (
                                            <Button
                                                variant="outlined"
                                                color={immediateAnswerFeedback ? "success" : "primary"}
                                                onClick={toggleImmediateFeedback}
                                                size="small"
                                                fullWidth={isMobile}
                                                sx={{
                                                    fontWeight: 'medium',
                                                    fontSize: { xs: '0.875rem', sm: '1rem' }
                                                }}
                                            >
                                                {immediateAnswerFeedback ? "משוב מיידי: פעיל" : "משוב מיידי: כבוי"}
                                            </Button>
                                        )}

                                        <Button
                                            variant={quizMode ? "outlined" : "contained"}
                                            color={quizMode ? "error" : "primary"}
                                            onClick={toggleQuizMode}
                                            fullWidth={isMobile}
                                            sx={{
                                                fontWeight: 'bold',
                                                px: { xs: 2, sm: 3 },
                                                py: { xs: 1, sm: 1.5 },
                                                borderRadius: 2,
                                                boxShadow: quizMode ? 'none' : 3,
                                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                                '&:hover': {
                                                    transform: 'translateY(-2px)',
                                                    boxShadow: quizMode ? 1 : 4,
                                                },
                                                transition: 'all 0.2s ease'
                                            }}
                                        >
                                            {quizMode ? "צא ממצב מבחן" : "התחל מבחן"}
                                        </Button>
                                    </Box>
                                </Box>

                                {quizMode && showResults && (
                                    <Paper sx={{
                                        p: 2,
                                        mb: 3,
                                        bgcolor: alpha(theme.palette.success.main, 0.1),
                                        borderRadius: 2,
                                        border: `1px solid ${alpha(theme.palette.success.main, 0.3)}`
                                    }}>
                                        <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                            תוצאות המבחן
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center', mt: 1 }}>
                                            ענית נכון על {score.correct} מתוך {score.total} שאלות
                                            ({Math.round((score.correct / score.total) * 100)}%)
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={resetQuiz}
                                                startIcon={<RotateLeftIcon />}
                                            >
                                                נסה שוב
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={toggleQuizMode}
                                            >
                                                צא ממצב מבחן
                                            </Button>
                                        </Box>
                                    </Paper>
                                )}

                                {quizMode && !showResults && (
                                    <Paper sx={{
                                        p: 2,
                                        mb: 3,
                                        bgcolor: alpha(theme.palette.primary.main, 0.05),
                                        borderRadius: 2,
                                        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`
                                    }}>
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                            {immediateAnswerFeedback
                                                ? "מצב מבחן פעיל עם משוב מיידי - תקבל משוב מיד לאחר בחירת תשובה"
                                                : "מצב מבחן פעיל - בחר את התשובות הנכונות ולחץ על \"סיים מבחן\" כדי לראות את התוצאות"}
                                        </Typography>

                                        {immediateAnswerFeedback && Object.keys(userAnswers).length > 0 && (
                                            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1, fontWeight: 'medium' }}>
                                                ציון נוכחי: {score.correct} תשובות נכונות מתוך {Object.keys(userAnswers).length} שאלות שנענו
                                                ({Math.round((score.correct / Object.keys(userAnswers).length) * 100)}%)
                                            </Typography>
                                        )}

                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={calculateScore}
                                                disabled={Object.keys(userAnswers).length === 0}
                                            >
                                                סיים מבחן
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={toggleQuizMode}
                                            >
                                                בטל מבחן
                                            </Button>
                                        </Box>
                                    </Paper>
                                )}

                                {/* Group questions by test */}
                                {Object.entries(groupQuestionsByTest(selectedQuestions)).map(([testId, questions]) => {
                                    const test = findTestByQuestionId(questions[0].id);
                                    return (
                                        <Box key={testId} sx={{ mb: 4 }}>
                                            <Paper sx={{
                                                p: 2,
                                                mb: 2,
                                                bgcolor: alpha(theme.palette.primary.main, 0.05),
                                                borderRadius: 2
                                            }}>
                                                <Typography variant="subtitle1" fontWeight="bold" sx={{ textAlign: 'right' }}>
                                                    {test?.test.title} ({questions.length} שאלות)
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right' }}>
                                                    {test?.test.subject} • {test?.test.date} • מועד {test?.test.term}
                                                </Typography>
                                            </Paper>

                                            {questions.map(question => {
                                                const similarQuestions = question.similar_questions
                                                    .map(similar => ({
                                                        question: findQuestionById(similar.id),
                                                        similarity: similar.similarity_score,
                                                        test: findTestByQuestionId(similar.id)
                                                    }))
                                                    .filter(item => item.question !== undefined && item.similarity > similarityThreshold)
                                                    .sort((a, b) => b.similarity - a.similarity);
                                                const test = findTestByQuestionId(question.id);
                                                const isAnswered = userAnswers[question.id] !== undefined;
                                                const isCorrect = userAnswers[question.id] === question.correct_answer;

                                                return (
                                                    <QuestionCard key={question.id}>
                                                        <CardContent>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                    {question.page_number && (
                                                                        <>
                                                                            <Tooltip title="צפה בעמוד המקורי במבחן" arrow>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => openPdfViewer(question.page_number || 1, question.id)}
                                                                                    onMouseEnter={(e) => handlePdfPreviewEnter(e, question.page_number || 1, question.id)}
                                                                                    onMouseLeave={handlePdfPreviewLeave}
                                                                                    sx={{
                                                                                        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                                                                                        width: 36,
                                                                                        height: 36,
                                                                                        '&:hover': {
                                                                                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                                                            transform: 'scale(1.1)',
                                                                                            transition: 'all 0.2s ease'
                                                                                        },
                                                                                        transition: 'all 0.2s ease'
                                                                                    }}
                                                                                >
                                                                                    <PictureAsPdfIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="צפה בעמוד התשובות" arrow>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => test?.test?.answerPage && openPdfViewer(test.test.answerPage, question.id)}
                                                                                    onMouseEnter={(e) => handlePdfPreviewEnter(e, test?.test?.answerPage || null, question.id)}
                                                                                    onMouseLeave={handlePdfPreviewLeave}
                                                                                    disabled={!test?.test?.answerPage}
                                                                                    sx={{
                                                                                        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                                                                                        width: 36,
                                                                                        height: 36,
                                                                                        '&:hover': {
                                                                                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                                                            transform: 'scale(1.1)',
                                                                                            transition: 'all 0.2s ease'
                                                                                        },
                                                                                        transition: 'all 0.2s ease',
                                                                                        padding: '4px 8px',
                                                                                        fontSize: '0.75rem'
                                                                                    }}
                                                                                >
                                                                                    <Typography variant="caption" color="text.secondary" noWrap>
                                                                                        תשובות
                                                                                    </Typography>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                    {similarQuestions.length > 0 && (
                                                                        <Tooltip title="שאלות דומות" arrow>
                                                                            <Chip
                                                                                sx={{ display: { xs: 'none', sm: 'block' }, direction: 'ltr' }}
                                                                                icon={<CompareArrowsIcon />}
                                                                                label={similarQuestions.length}
                                                                                color="secondary"
                                                                                onClick={() => toggleQuestionExpanded(question.id)}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ textAlign: 'right' }}>
                                                                    <Typography variant="subtitle2" color="text.secondary">
                                                                        {test?.test.title} - שאלה {question.question_number} - עמוד {question.page_number}
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5, justifyContent: 'flex-end' }}>
                                                                        {question.topics && Array.isArray(question.topics) ?
                                                                            question.topics.map(topic => (
                                                                                <Chip
                                                                                    key={topic}
                                                                                    label={topic}
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    variant="outlined"
                                                                                    sx={{ borderRadius: 1 }}
                                                                                />
                                                                            )) :
                                                                            (typeof question.topics === 'string' ? (
                                                                                <Chip
                                                                                    key={question.topics}
                                                                                    label={question.topics}
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    variant="outlined"
                                                                                    sx={{ borderRadius: 1 }}
                                                                                />
                                                                            ) : null)
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </Box>

                                                            <Typography variant="body1" gutterBottom sx={{ mb: 2, textAlign: 'right' }}>
                                                                {question.question_text}
                                                            </Typography>

                                                            <Grid container spacing={1} sx={{ direction: 'rtl' }}>
                                                                {question.options.map((option, index) => (
                                                                    <Grid item xs={12} sm={6} key={`${question.id}-${index}`}>
                                                                        <Paper
                                                                            key={`${question.id}-${index}`}
                                                                            onClick={() => handleOptionSelect(question.id, index)}
                                                                            sx={{
                                                                                direction: 'rtl',
                                                                                p: 1.5,
                                                                                borderRadius: 1,
                                                                                bgcolor: getOptionBackgroundColor(question, index, userAnswers, showResults, quizMode),
                                                                                border: getOptionBorderColor(question, index, userAnswers, showResults, quizMode),
                                                                                textAlign: 'right',
                                                                                cursor: quizMode && !showResults ? 'pointer' : 'default',
                                                                                transition: 'all 0.2s ease',
                                                                                '&:hover': quizMode && !showResults ? {
                                                                                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                                                                                    transform: 'translateY(-2px)',
                                                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                                                                                } : {},
                                                                                position: 'relative',
                                                                                '&::after': quizMode && userAnswers[question.id] === (index + 1).toString() && !showResults ? {
                                                                                    content: '""',
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    right: 0,
                                                                                    bottom: 0,
                                                                                    borderRadius: 1,
                                                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                                                    pointerEvents: 'none'
                                                                                } : {}
                                                                            }}
                                                                        >
                                                                            <Typography variant="body2" sx={{ textAlign: 'right', direction: 'rtl' }}>
                                                                                <strong>{String.fromCharCode(1488 + index)}.</strong> {option.text}
                                                                            </Typography>
                                                                        </Paper>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>

                                                            {quizMode && (showResults || (immediateAnswerFeedback && isAnswered)) && (
                                                                <Box sx={{ mt: 2, textAlign: 'right' }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: isCorrect ? 'success.main' : 'error.main',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'right',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'flex-end',
                                                                            gap: 1
                                                                        }}
                                                                    >
                                                                        {isCorrect ? (
                                                                            <>
                                                                                <Box component="span" sx={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    borderRadius: '50%',
                                                                                    bgcolor: alpha(theme.palette.success.main, 0.2),
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    color: theme.palette.success.main,
                                                                                    fontWeight: 'bold'
                                                                                }}>✓</Box>
                                                                                תשובה נכונה!
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Box component="span" sx={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    borderRadius: '50%',
                                                                                    bgcolor: alpha(theme.palette.error.main, 0.2),
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    color: theme.palette.error.main,
                                                                                    fontWeight: 'bold'
                                                                                }}>✗</Box>
                                                                                תשובה שגויה. התשובה הנכונה היא: {question.correct_answer && String.fromCharCode(1487 + parseInt(question.correct_answer))}
                                                                            </>
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            )}

                                                            {similarQuestions.length > 0 && (
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Button
                                                                        variant="text"
                                                                        color="primary"
                                                                        onClick={() => toggleQuestionExpanded(question.id)}
                                                                        endIcon={expandedQuestions[question.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                                        sx={{ textAlign: 'right' }}
                                                                    >
                                                                        {expandedQuestions[question.id] ? 'הסתר' : 'הצג'} שאלות דומות ({similarQuestions.length})
                                                                    </Button>

                                                                    <Collapse in={expandedQuestions[question.id]}>
                                                                        <SimilarQuestionsCard>
                                                                            <List sx={{ direction: 'rtl', textAlign: 'right' }}>
                                                                                {similarQuestions.map(({ question: similarQuestion, similarity, test }) => (
                                                                                    similarQuestion && (
                                                                                        <ListItem key={similarQuestion.id} divider>
                                                                                            <ListItemIcon sx={{ minWidth: 40 }}>
                                                                                                <Chip
                                                                                                    label={`${(similarity * 100).toFixed(0)}%`}
                                                                                                    size="small"
                                                                                                    color={similarity > 0.8 ? "error" : similarity > 0.5 ? "warning" : "primary"}
                                                                                                    sx={{ height: 24, marginLeft: 2 }}
                                                                                                />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText
                                                                                                primary={
                                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                        <Typography variant="body2" fontWeight="medium" sx={{ textAlign: 'right' }}>
                                                                                                            {findTestByQuestionId(similarQuestion.id)?.test.title} - שאלה {similarQuestion.question_number} - עמוד {similarQuestion.page_number}
                                                                                                        </Typography>
                                                                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                                                            {similarQuestion.page_number && (
                                                                                                                <>
                                                                                                                    <Tooltip title="צפה בעמוד המקורי במבחן" arrow>
                                                                                                                        <IconButton
                                                                                                                            size="small"
                                                                                                                            color="primary"
                                                                                                                            onClick={() => openPdfViewer(similarQuestion.page_number || 1, similarQuestion.id)}
                                                                                                                            onMouseEnter={(e) => handlePdfPreviewEnter(e, similarQuestion.page_number || 1, similarQuestion.id)}
                                                                                                                            onMouseLeave={handlePdfPreviewLeave}
                                                                                                                            sx={{
                                                                                                                                '&:hover': {
                                                                                                                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                                                                                                    transform: 'scale(1.1)',
                                                                                                                                    transition: 'all 0.2s ease'
                                                                                                                                },
                                                                                                                                transition: 'all 0.2s ease'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <PictureAsPdfIcon fontSize="small" />
                                                                                                                        </IconButton>
                                                                                                                    </Tooltip>
                                                                                                                    <Tooltip title="צפה בעמוד התשובות" arrow>
                                                                                                                        <IconButton
                                                                                                                            color="primary"
                                                                                                                            size="small"
                                                                                                                            onClick={() => test?.test?.answerPage && openPdfViewer(test.test.answerPage, question.id)}
                                                                                                                            onMouseEnter={(e) => handlePdfPreviewEnter(e, findTestByQuestionId(similarQuestion.id)?.test?.answerPage || null, question.id)}
                                                                                                                            onMouseLeave={handlePdfPreviewLeave}
                                                                                                                            disabled={!findTestByQuestionId(similarQuestion.id)?.test?.answerPage}
                                                                                                                            sx={{
                                                                                                                                border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                                                                                                                                width: 36,
                                                                                                                                height: 36,
                                                                                                                                '&:hover': {
                                                                                                                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                                                                                                    transform: 'scale(1.1)',
                                                                                                                                    transition: 'all 0.2s ease'
                                                                                                                                },
                                                                                                                                transition: 'all 0.2s ease',
                                                                                                                                padding: '4px 8px',
                                                                                                                                fontSize: '0.75rem'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <Typography variant="caption" color="text.secondary">
                                                                                                                                תשובות
                                                                                                                            </Typography>
                                                                                                                        </IconButton>
                                                                                                                    </Tooltip>
                                                                                                                </>
                                                                                                            )}
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                }
                                                                                                secondary={
                                                                                                    <>
                                                                                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5, textAlign: 'right' }}>
                                                                                                            {similarQuestion.question_text}
                                                                                                        </Typography>
                                                                                                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5, textAlign: 'right' }}>
                                                                                                            <Box component="span" sx={{ fontWeight: 'bold' }}>אפשרויות:</Box><br />
                                                                                                            {similarQuestion.options.map((opt, idx) => (
                                                                                                                <React.Fragment key={idx}>
                                                                                                                    {String.fromCharCode(1488 + idx)}. {opt.text}<br />
                                                                                                                </React.Fragment>
                                                                                                            ))}
                                                                                                        </Typography>
                                                                                                    </>
                                                                                                }
                                                                                                secondaryTypographyProps={{
                                                                                                    component: 'div'
                                                                                                }}
                                                                                            />
                                                                                        </ListItem>
                                                                                    )
                                                                                ))}
                                                                            </List>
                                                                        </SimilarQuestionsCard>
                                                                    </Collapse>
                                                                </Box>
                                                            )}
                                                        </CardContent>
                                                    </QuestionCard>
                                                );
                                            })}
                                        </Box>
                                    );
                                })}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleBack}
                                    sx={{ mt: 2 }}
                                >
                                    חזור לבחירת מבחנים
                                </Button>
                            </Box>
                        )}
                    </ContentPaper>
                </PageContainer>
            </PageContainer>

            {/* Add the draggable PDF viewer */}
            {draggablePdf && (
                <Portal>
                    <Draggable
                        defaultPosition={draggablePdf.position}
                        handle=".drag-handle"
                    >
                        <FloatingLectureCard sx={{
                            width: draggablePdf.isMinimized ? '300px' : draggablePdf.isFullSize ? '80vw' : '800px',
                            height: draggablePdf.isMinimized ? '100px' : draggablePdf.isMinimized ? '100px' : draggablePdf.isFullSize ? '80vh' : '1000px',
                            transition: 'width 0.3s ease, height 0.3s ease',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <DragHandle className="drag-handle">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <DragIndicatorIcon color="action" />
                                    <Typography variant="subtitle2">
                                        {draggablePdf.subject} - עמוד {draggablePdf.pageNumber}
                                    </Typography>
                                </Stack>
                                <Box>
                                    <IconButton
                                        size="small"
                                        onClick={handleTogglePdfSize}
                                        title={draggablePdf.isFullSize ? "הקטן" : "הגדל"}
                                    >
                                        {draggablePdf.isFullSize ?
                                            <ZoomOutIcon fontSize="small" /> :
                                            <ZoomInIcon fontSize="small" />}
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={handleMinimizeDraggablePdf}
                                        title={draggablePdf.isMinimized ? "הרחב" : "מזער"}
                                    >
                                        {draggablePdf.isMinimized ? <OpenInFullIcon /> : <MinimizeIcon />}
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={handleCloseDraggablePdf}
                                        title="סגור"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </DragHandle>

                            <DialogContent sx={{
                                p: 0,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden'
                            }}>
                                {draggablePdf.isMinimized ? (
                                    <Box width="300px" height="100px" display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="body2" noWrap>
                                            {draggablePdf.subject} - עמוד {draggablePdf.pageNumber}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        <Box sx={{
                                            flexGrow: 1,
                                            overflow: 'hidden',
                                            position: 'relative'
                                        }}>
                                            <PdfViewer
                                                subject={draggablePdf.subject}
                                                pageNumber={draggablePdf.pageNumber}
                                                pdfPath={pdfPath}
                                            />
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            p: 1,
                                            borderTop: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
                                            bgcolor: alpha(theme.palette.background.default, 0.7)
                                        }}>
                                            <Button
                                                onClick={() => handleChangePdfPage(-1)}
                                                disabled={draggablePdf.pageNumber <= 1}
                                                size="small"
                                                startIcon={<ArrowBackIcon />}
                                            >
                                                הקודם
                                            </Button>
                                            <Typography variant="body2">
                                                עמוד {draggablePdf.pageNumber}
                                            </Typography>
                                            <Button
                                                onClick={() => handleChangePdfPage(1)}
                                                disabled={draggablePdf.pageNumber >= 100}
                                                size="small"
                                                endIcon={<ArrowBackIcon sx={{ transform: 'rotate(180deg)' }} />}
                                            >
                                                הבא
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </DialogContent>
                        </FloatingLectureCard>
                    </Draggable>
                </Portal>
            )}

            {/* Update the PDF preview popper to use the correct PDF path */}
            {pdfPreviewOpen && pdfPreviewAnchorEl && (
                <PdfPreviewPopper
                    open={pdfPreviewOpen}
                    anchorEl={pdfPreviewAnchorEl}
                    placement="right"
                    pageNumber={previewPageNumber || 1}
                    onClose={closePdfPreview}
                    onFullscreen={() => {
                        const subject = pdfPreviewAnchorEl.getAttribute('data-subject') || activeTestSet;
                        closePdfPreview();
                        if (previewPageNumber) {
                            openPdfViewer(previewPageNumber - 1, '');
                        }
                    }}
                    handlePdfPreviewLeave={handlePdfPreviewLeave}
                    pdfPath={pdfPath}
                    selectedTest={{
                        test: {
                            subject: pdfPreviewAnchorEl.getAttribute('data-subject') || activeTestSet,
                            id: '',
                            title: '',
                            date: null,
                            term: null,
                            year: null,
                            organization: null,
                            faculty: null,
                            instructors: [],
                            metadata: {
                                pdf_path: '',
                                extraction_model: '',
                                extraction_date: '',
                                pages_processed: 0,
                                total_questions: 0,
                                has_answers: false,
                                source_file: ''
                            }
                        },
                        questions: []
                    }}
                />
            )}
        </>
    );
};

export default TestAnalyzerPage; 