import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Slider,
    Checkbox,
    FormControlLabel,
    Button,
    Chip,
    Divider,
    Stack,
    TextField,
    Autocomplete,
    Paper,
    alpha,
    useTheme,
    Tooltip,
    IconButton,
    Badge,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalyzerTest, AnalyzerQuestion, SimilarQuestion } from '../Constants/interface';
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from '@mui/icons-material/FilterList';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoIcon from '@mui/icons-material/Info';
import { logCustomEvent } from '../../utils/Analytics';

export interface TestAnalyzerProps {
    tests: AnalyzerTest[];
    onQuestionsSelected: (questions: AnalyzerQuestion[]) => void;
    similarityThreshold: number;
    onSimilarityThresholdChange: (threshold: number) => void;
    activeTestSet: string;
}

interface SubjectGroup {
    subject: string;
    tests: AnalyzerTest[];
}

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    background: theme.palette.background.paper,
    backdropFilter: 'blur(10px)',
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    transition: 'all 0.3s ease',
    direction: 'rtl',
    textAlign: 'right',
    '&:hover': {
        boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
        transform: 'translateY(-2px)',
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(1),
    }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.primary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}));

const sortByAlphabet = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

const sortTests = (a: AnalyzerTest, b: AnalyzerTest): number => {
    return a.test.title.localeCompare(b.test.title, undefined, {
        numeric: true,
        sensitivity: "base",
    });
};

const TestAnalyzer: React.FC<TestAnalyzerProps> = ({ tests, onQuestionsSelected, similarityThreshold, onSimilarityThresholdChange, activeTestSet }) => {
    const theme = useTheme();
    const [selectedTests, setSelectedTests] = useState<Set<string>>(new Set());
    const [selectedTreeItem, setSelectedTreeItem] = useState<string | null>(null);
    const [showOnlySimilarQuestions, setShowOnlySimilarQuestions] = useState<boolean>(false);
    const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
    const [questionPool, setQuestionPool] = useState<AnalyzerQuestion[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Group tests by subject
    const groupedTests = useMemo(() => {
        const groups: { [key: string]: AnalyzerTest[] } = {};
        tests.forEach(test => {
            const subject = test.test.subject || activeTestSet;
            if (!groups[subject]) {
                groups[subject] = [];
            }
            groups[subject].push(test);
        });

        // Sort tests within each subject
        Object.values(groups).forEach(testsArray => {
            testsArray.sort(sortTests);
        });

        return groups;
    }, [tests]);

    // Extract all unique topics from all questions
    const allTopics = useMemo(() => {
        const topicsSet = new Set<string>();
        tests.forEach(test => {
            test.questions.forEach(question => {
                // Check if topics exists and is an array before calling forEach
                if (question.topics && Array.isArray(question.topics)) {
                    question.topics.forEach(topic => {
                        topicsSet.add(topic);
                    });
                } else if (question.topics && typeof question.topics === 'string') {
                    // Handle case where topics might be a single string
                    topicsSet.add(question.topics);
                }
            });
        });
        return Array.from(topicsSet).sort();
    }, [tests]);

    // Load questions for selected tests
    useEffect(() => {
        if (selectedTests.size === 0) {
            setQuestionPool([]);
            return;
        }

        setIsLoading(true);
        try {
            const selectedQuestions: AnalyzerQuestion[] = [];

            // Collect all questions from selected tests
            tests.forEach(test => {
                if (selectedTests.has(test.test.id)) {
                    selectedQuestions.push(...test.questions);
                }
            });

            setQuestionPool(selectedQuestions);
        } catch (error) {
            console.error('Error loading questions:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedTests, tests]);

    const handleTestToggle = (testId: string) => {
        const newSelected = new Set(selectedTests);
        if (newSelected.has(testId)) {
            newSelected.delete(testId);
        } else {
            newSelected.add(testId);
        }
        setSelectedTests(newSelected);
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        itemId: string | null
    ) => {
        setSelectedTreeItem(itemId);
        if (itemId) {
            const test = tests.find(t => t.test.id === itemId);
            if (test) {
                handleTestToggle(itemId);
            }
            logCustomEvent('test_selected_for_analyzer', { testId: itemId, lectureId: test?.test?.title });
        }
    };

    // Find similar questions based on the similarity threshold
    const findSimilarQuestions = () => {
        if (questionPool.length === 0) return [];

        // Create a map of questions with similar questions above the threshold
        const questionsWithSimilars = questionPool.filter(question =>
            question.similar_questions && Array.isArray(question.similar_questions) &&
            question.similar_questions.some(similar =>
                similar.similarity_score >= similarityThreshold
            )
        );

        // If we're showing only similar questions, filter the pool
        if (showOnlySimilarQuestions) {
            // Get all question IDs that are similar to any other question
            const similarQuestionIds = new Set<string>();

            questionsWithSimilars.forEach(question => {
                similarQuestionIds.add(question.id);
                if (question.similar_questions && Array.isArray(question.similar_questions)) {
                    question.similar_questions
                        .filter(similar => similar.similarity_score >= similarityThreshold)
                        .forEach(similar => similarQuestionIds.add(similar.id));
                }
            });

            // Filter the question pool to only include questions that are similar to others
            const filteredQuestions = questionPool.filter(question =>
                similarQuestionIds.has(question.id)
            );

            return filteredQuestions;
        }

        return questionPool;
    };

    // Filter questions by selected topics
    const filterQuestionsByTopics = (questions: AnalyzerQuestion[]) => {
        if (selectedTopics.length === 0) return questions;

        return questions.filter(question => {
            if (!question.topics) return false;

            if (Array.isArray(question.topics)) {
                return question.topics.some(topic => selectedTopics.includes(topic));
            } else if (typeof question.topics === 'string') {
                return selectedTopics.includes(question.topics);
            }

            return false;
        });
    };

    // Get the final filtered questions
    const filteredQuestions = useMemo(() => {
        const similarQuestions = findSimilarQuestions();
        return filterQuestionsByTopics(similarQuestions);
    }, [questionPool, similarityThreshold, showOnlySimilarQuestions, selectedTopics]);

    // Count questions with similar questions above threshold
    const questionsWithSimilarsCount = useMemo(() => {
        return questionPool.filter(question =>
            question.similar_questions && Array.isArray(question.similar_questions) &&
            question.similar_questions.some(similar =>
                similar.similarity_score >= similarityThreshold
            )
        ).length;
    }, [questionPool, similarityThreshold]);

    const generateAnalysis = () => {
        onQuestionsSelected(filteredQuestions);
        logCustomEvent('test_analyzer_generate_analysis', { testId: selectedTests.size, lectureId: tests.find(t => selectedTests.has(t.test.id))?.test?.title });
    }

    // In the logCustomEvent call for topics, ensure we handle non-array topics:
    const getTopicsFromQuestion = (q: AnalyzerQuestion): string[] => {
        if (!q.topics) return [];
        if (Array.isArray(q.topics)) return q.topics;
        if (typeof q.topics === 'string') return [q.topics];
        return [];
    };


    const handleShowOnlySimilarQuestions = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlySimilarQuestions(e.target.checked)
        try {
            logCustomEvent('test_analyzer_show_only_similar_questions', {
                selectedTests: Array.from(selectedTests.values()),
                showOnlySimilarQuestions: e.target.checked
            });
        } catch (error) {
            console.error('Error logging event:', error);
        }
    }
    return (
        <Box sx={{ p: { xs: 1, sm: 2 }, direction: 'rtl', maxWidth: '800px', margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom sx={{
                mb: { xs: 2, sm: 3 },
                color: theme.palette.text.primary,
                fontWeight: 500,
                textAlign: 'center',
                fontSize: { xs: '1.25rem', sm: '1.5rem' }
            }}>
                מנתח מבחנים
            </Typography>

            <StyledCard>
                <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 500, textAlign: 'right' }}>
                        בחר מבחנים ({selectedTests.size} נבחרו)
                    </Typography>
                    <Box sx={{
                        maxHeight: { xs: '300px', sm: '400px' },
                        overflowY: 'auto',
                        minHeight: { xs: '150px', sm: '200px' },
                        direction: 'rtl',
                        "&::-webkit-scrollbar": {
                            width: "8px",
                            background: "transparent",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "rgba(255, 255, 255, 0.05)",
                            borderRadius: "10px",
                            margin: theme.spacing(1),
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "rgba(255, 255, 255, 0.2)",
                            borderRadius: "10px",
                            border: "2px solid transparent",
                            backgroundClip: "padding-box",
                            transition: "all 0.2s ease",
                            "&:hover": {
                                background: "rgba(255, 255, 255, 0.3)",
                                boxShadow: "0 0 8px rgba(74, 144, 226, 0.5)",
                            },
                        },
                    }}>
                        <SimpleTreeView
                            selectedItems={selectedTreeItem}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            multiSelect={false}
                            slots={{
                                expandIcon: ChevronRightIcon,
                                collapseIcon: ExpandMoreIcon
                            }}
                            sx={{
                                color: theme.palette.text.primary,
                                direction: 'rtl',
                                "& .MuiTreeItem-content": {
                                    padding: { xs: "2px 4px", sm: "4px 8px" },
                                    borderRadius: "8px",
                                    transition: "all 0.2s ease",
                                    "&:hover": {
                                        background: alpha(theme.palette.primary.main, 0.08),
                                    },
                                    "&.Mui-selected": {
                                        background: alpha(theme.palette.primary.main, 0.15),
                                    },
                                },
                                "& .MuiTreeItem-label": {
                                    fontSize: { xs: "1rem", sm: "1.25rem" },
                                    fontWeight: 400,
                                    color: theme.palette.text.primary,
                                    textAlign: 'right',
                                },
                                "& .MuiTreeItem-group": {
                                    marginLeft: { xs: "12px", sm: "17px" },
                                    paddingLeft: { xs: "12px", sm: "18px" },
                                    borderLeft: `1px dashed ${alpha(theme.palette.divider, 0.4)}`,
                                },
                            }}
                        >
                            {Object.entries(groupedTests)
                                .sort(([a], [b]) => sortByAlphabet(a, b))
                                .map(([subject, subjectTests]) => (
                                    <TreeItem
                                        key={subject}
                                        itemId={subject}
                                        label={
                                            <Typography variant="subtitle2" sx={{
                                                fontWeight: 500,
                                                fontSize: "1rem",
                                                color: theme.palette.text.primary,
                                                textAlign: 'right',
                                                width: '100%'
                                            }}>
                                                {subject} ({subjectTests.length})
                                            </Typography>
                                        }
                                    >
                                        {subjectTests.map(test => (
                                            <TreeItem
                                                key={test.test.id}
                                                itemId={test.test.id}
                                                label={
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={selectedTests.has(test.test.id)}
                                                                onChange={() => handleTestToggle(test.test.id)}
                                                                sx={{
                                                                    color: theme.palette.primary.main,
                                                                    '&.Mui-checked': {
                                                                        color: theme.palette.primary.main,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                                                <Typography variant="body2" sx={{ fontSize: '0.875rem', textAlign: 'right' }}>
                                                                    {test.test.title}
                                                                </Typography>
                                                                <Chip
                                                                    size="small"
                                                                    label={test.questions.length}
                                                                    sx={{ m: 1, height: '20px', fontSize: '0.7rem' }}
                                                                />
                                                            </Box>
                                                        }
                                                        sx={{
                                                            m: 0,
                                                            width: '100%',
                                                            justifyContent: 'flex-end',
                                                            '& .MuiFormControlLabel-label': {
                                                                fontSize: '0.875rem',
                                                                color: theme.palette.text.primary,
                                                                textAlign: 'right',
                                                                width: '100%'
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        ))}
                                    </TreeItem>
                                ))}
                        </SimpleTreeView>
                    </Box>
                </CardContent>
            </StyledCard>

            {/* Similarity Settings */}
            <StyledCard>
                <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: 1, sm: 0 }
                    }}>
                        <Typography variant="subtitle1" gutterBottom sx={{
                            mb: { xs: 0, sm: 0 },
                            fontWeight: 500,
                            textAlign: 'right',
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            הגדרות דמיון בין שאלות
                        </Typography>
                        <Tooltip title="שאלות דומות הן שאלות שמופיעות במבחנים שונים אך בודקות את אותו הידע">
                            <IconButton size="small">
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{
                        px: { xs: 1, sm: 3 },
                        py: { xs: 1, sm: 2 },
                        direction: 'rtl',
                        textAlign: 'right'
                    }}>
                        <Typography gutterBottom sx={{ textAlign: 'right' }}>
                            סף דמיון ({(similarityThreshold * 100).toFixed(0)}%)
                        </Typography>
                        <StyledSlider
                            value={similarityThreshold}
                            onChange={(_, value) => onSimilarityThresholdChange(value as number)}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${(value * 100).toFixed(0)}%`}
                            step={0.01}
                            marks={[
                                { value: 0.01, label: '1%' },
                                { value: 0.1, label: '10%' },
                                { value: 0.3, label: '30%' },
                                { value: 0.5, label: '50%' },
                                { value: 0.7, label: '70%' },
                                { value: 0.9, label: '90%' },
                            ]}
                            min={0.001}
                            max={0.999}
                            sx={{ direction: 'ltr' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showOnlySimilarQuestions}
                                    onChange={(e) => handleShowOnlySimilarQuestions(e)}
                                />
                            }
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'rtl' }}>
                                    <Typography variant="body2" sx={{ textAlign: 'right' }}>הצג רק שאלות דומות</Typography>
                                    {questionsWithSimilarsCount > 100 ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
                                            <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                                {questionsWithSimilarsCount}
                                            </Typography>
                                            <CompareArrowsIcon fontSize="small" />
                                        </Box>
                                    ) : (
                                        <Badge
                                            badgeContent={questionsWithSimilarsCount}
                                            color="primary"
                                            sx={{ m: 1 }}
                                        >
                                            <CompareArrowsIcon fontSize="small" />
                                        </Badge>
                                    )}
                                </Box>
                            }
                            sx={{
                                marginRight: 0,
                                marginLeft: 'auto',
                                '& .MuiFormControlLabel-label': {
                                    textAlign: 'right'
                                }
                            }}
                        />
                    </Box>
                </CardContent>
            </StyledCard>

            {/* Topics Filter */}
            <StyledCard>
                <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: 1, sm: 0 }
                    }}>
                        <Typography variant="subtitle1" gutterBottom sx={{
                            mb: { xs: 0, sm: 0 },
                            fontWeight: 500,
                            textAlign: 'right',
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            סינון לפי נושאים
                        </Typography>
                        <FilterListIcon />
                    </Box>
                    <Autocomplete
                        multiple
                        options={allTopics}
                        value={selectedTopics}
                        onChange={(_, newValue) => setSelectedTopics(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="בחר נושאים"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        textAlign: 'right',
                                        direction: 'rtl',
                                        fontSize: { xs: '0.875rem', sm: '1rem' }
                                    }
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { textAlign: 'right', direction: 'rtl' }
                                }}
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                    sx={{
                                        m: 0.5,
                                        fontSize: { xs: '0.75rem', sm: '0.875rem' }
                                    }}
                                />
                            ))
                        }
                        sx={{ direction: 'rtl' }}
                    />
                </CardContent>
            </StyledCard>

            {/* Generate Button */}
            <Button
                variant="contained"
                fullWidth
                onClick={generateAnalysis}
                disabled={selectedTests.size === 0 || isLoading}
                sx={{
                    mt: 2,
                    py: { xs: 1, sm: 1.5 },
                    px: { xs: 2, sm: 3 },
                    borderRadius: 2,
                    background: theme.palette.primary.main,
                    direction: 'rtl',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                    transition: 'all 0.3s ease',
                }}
            >
                {isLoading ? 'טוען שאלות...' : 'הצג ניתוח'} ({filteredQuestions.length} שאלות)
            </Button>

            {/* Question Pool Stats */}
            {questionPool.length > 0 && (
                <Paper
                    sx={{
                        mt: 2,
                        p: { xs: 1.5, sm: 2 },
                        borderRadius: 2,
                        bgcolor: alpha(theme.palette.background.paper, 0.8),
                        backdropFilter: 'blur(10px)',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        direction: 'rtl',
                        textAlign: 'right',
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="body2" color="text.secondary" sx={{
                            textAlign: 'right',
                            fontSize: { xs: '0.75rem', sm: '0.875rem' }
                        }}>
                            מאגר שאלות זמין: {questionPool.length} שאלות
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end'
                        }}>
                            <Chip
                                label={`שאלות דומות: ${questionsWithSimilarsCount}`}
                                size="small"
                                color="primary"
                                sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                            />
                            <Chip
                                label={`נושאים: ${allTopics.length}`}
                                size="small"
                                color="secondary"
                                sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                            />
                        </Box>
                    </Stack>
                </Paper>
            )}
        </Box>
    );
}

export default TestAnalyzer; 